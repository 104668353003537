import { createSlice } from "@reduxjs/toolkit";
import { pushNotification } from "../../api/pushNotification";

const initialState = {
  data: null,
  isLoading: false,
};

export const pushNotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(pushNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(pushNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(pushNotification.rejected, (state) => {
        state.isLoading = false;
        state.data = null;
      });
  },
});

export default pushNotificationSlice.reducer;
