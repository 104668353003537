import React, { useEffect } from "react";
import Layout from "../../Layouts/Layout";
import { Button, Card, Col, Form, Input, message, Row, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../Components/Ui/Flex";
import { addGallery } from "../../store/api/addGallery";

const AddGallery = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAddingLoading } = useSelector((state) => state.addGalleryReducer);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onDiscard = () => {
    navigate("/gallery");
    form.resetFields();
  };

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);

    if (values.images && values.images.length > 0) {
      values.images.forEach((file) => {
        formData.append("images[]", file.originFileObj);
      });
    }

    dispatch(addGallery(formData))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("Gallery successfully added!");
          navigate("/gallery");
        } else {
          message.error(response.Message);
        }
      })
      .catch((error) => {
        message.error("Failed to add event. Please try again.");
        console.error("Add event error:", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const handleRemove = (key) => {
    form.setFieldsValue({
      [key]: null,
    });
  };

  const beforeUpload = (file) => {
    const isValidFileType = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/jpg",
    ].includes(file.type);

    if (!isValidFileType) {
      message.error("You can only upload JPG, PNG, or WEBP files!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = ({ fileList }) => {
    form.setFieldsValue({
      images: fileList,
    });
  };

  const handleUploadRequest = ({ onSuccess }) => {
    onSuccess();
  };

  return (
    <Layout>
      <Card>
        <Form
          form={form}
          layout="vertical"
          name="advanced_search"
          className="ant-advanced-search-form"
          initialValues={{ status: "1" }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="container">
            <Flex
              className="py-2"
              mobileFlex={false}
              justifyContent="between"
              alignItems="center"
            >
              <h2 className="mb-3">Add New Gallery</h2>
              <div className="mb-3">
                <Button className="mr-2" onClick={onDiscard}>
                  Discard
                </Button>
                <Button
                  type="primary"
                  loading={isAddingLoading}
                  htmlType="submit"
                >
                  Add
                </Button>
              </div>
            </Flex>
          </div>
          <Col xs={24}>
            <Card title="Gallery Info">
              <Row gutter={[16, 16]}>
                <Col span={8} key={1} className="px-1">
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the gallery name",
                      },
                    ]}
                  >
                    <Input placeholder="Name" autoComplete="none" />
                  </Form.Item>
                </Col>
              </Row>
              <Col span={24}>
                <Form.Item
                  name="images"
                  label="Images"
                  rules={[
                    {
                      required: true,
                      message: "Please select the gallery images",
                    },
                  ]}
                >
                  <Upload
                    listType="picture"
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                    customRequest={handleUploadRequest}
                    onRemove={() => handleRemove("images")}
                    multiple
                  >
                    <Button>Upload Images</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Card>
          </Col>
        </Form>
      </Card>
    </Layout>
  );
};

export default AddGallery;
