import React from "react";

import { Row, Col, Card, Form, Input } from "antd";

export default function AdminOnlyForm() {
  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={17}>
        <Card title="Admin Only Information">
          <Form.Item
            label="Introducers Code"
            name="introducerCode"
            rules={[
              {
                required: true,
                message: "Introducer's Code is required",
              },
            ]}
          >
            <Input type="text" placeholder="Introducers Code" />
          </Form.Item>
          <Form.Item
            label="Seconder Code"
            name="seconderCode"
            rules={[
              {
                required: true,
                message: "Sub Introducer's Code is required",
              },
            ]}
          >
            <Input type="text" placeholder="Seconder Code" />
          </Form.Item>
          <Form.Item name="membershipTypeId" label="Type Of Membership">
            <Input
              type="text"
              placeholder="Type Of Membership"
              value={"1"}
              readOnly
            />
          </Form.Item>
          <Form.Item
            label="Registration Date"
            name="registrationDate"
            rules={[
              {
                required: true,
                message: "Please select your date of registration!",
              },
            ]}
          >
            <Input type="date" placeholder="" />
          </Form.Item>
          <Form.Item name="membershipRemarks" label="Membership Remarks">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={7}>
        <Card title="Organization">
          <Form.Item
            label="Member Code"
            name="membershipCode"
            rules={[
              {
                required: true,
                message: "Member's code is required",
              },
            ]}
          >
            <Input type="text" placeholder="Member Code" />
          </Form.Item>
          <Form.Item name="guestMemberCardNumber" label="Guest / Member Card">
            <Input type="text" placeholder="Guest / Member Card" />
          </Form.Item>
        </Card>
      </Col>
    </Row>
  );
}
