import React, { useState } from "react";
import { Upload, message, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { addGalleryImage } from "../../store/api/AddGalleryImage";
import { clearSingleGallery } from "../../store/feature/singleGallery/singleGallerySlice";
import { useParams } from "react-router-dom";
import { fetchGalleryById } from "../../store/api/fetchSingleGallery";
import { deleteGalleryImage } from "../../store/api/DeleteGalleryImage";

const PicturesWall = ({ gallery }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState(
    gallery.images.map((image) => ({
      uid: image.id,
      name: `Image-${image.id}`,
      url: image.galleryImage,
      status: "done",
    }))
  );

  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);

  const handlePreview = (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
  };

  const handleUpload = async ({ file }) => {
    try {
      const isValidFileType = [
        "image/jpeg",
        "image/png",
        "image/webp",
        "image/jpg",
      ].includes(file.type);

      if (!isValidFileType) {
        message.error("You can only upload JPG, PNG, or WEBP files!");
        return;
      }
      const formData = new FormData();
      formData.append("images[]", file);
      formData.append("id", gallery.id);
      formData.append("name", gallery.name);
      dispatch(addGalleryImage(formData))
        .unwrap()
        .then((response) => {
          if (response.Status) {
            message.success("Image Uploded successfully!");
            dispatch(clearSingleGallery());
            dispatch(fetchGalleryById({ id }));
          } else {
            message.error(response.Message);
          }
        });
    } catch (error) {
      message.error("Image upload failed");
    }
  };

  const handleRemove = async (file) => {
    try {
      dispatch(deleteGalleryImage({ id: file.uid, gallery_id: gallery.id }))
        .unwrap()
        .then((response) => {
          if (response.Status) {
            message.success("Image Deleted successfully!");
            setFileList(fileList.filter((item) => item.uid !== file.uid));
          } else {
            message.error(response.Message);
          }
        });
    } catch (error) {
      message.error("Failed to delete the image");
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div style={{ position: "relative" }}>
      <Upload
        listType="picture-circle"
        fileList={fileList}
        onPreview={handlePreview}
        customRequest={handleUpload}
        onRemove={handleRemove}
      >
        {uploadButton}
      </Upload>

      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default PicturesWall;
