import { Card, Col, Form, Input, Row, Switch } from "antd";
import React from "react";

const PushNotificationForm = (props) => {
  return (
    <Row gutter={24}>
      <Col xs={24} sm={24} md={24}>
        <Card title="Push Notification Settings">
          <Row>
            <Col span={8} key={2} className="px-1">
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Title",
                  },
                ]}
              >
                <Input placeholder="Title" autocomplete="none" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} key={1} className="px-1">
              <Form.Item
                label="Description"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter the description",
                  },
                ]}
              >
                <Input.TextArea rows={4} autocomplete="none" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} key={1} className="px-1">
              <Form.Item name="allMembers" label="All Members">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={4} key={1} className="px-1">
              <Form.Item name="staff" label="Staff">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default PushNotificationForm;
