import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Form, Button, Card, Upload, Result } from "antd";
import Flex from "../../Components/Ui/Flex";
import Layout from "../../Layouts/Layout";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";

const UploadMembers = () => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadButtonDisable, setUploadButtonDisable] = useState(true);
  const navigate = useNavigate();

  const getToMembers = () => {
    navigate("/members");
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (e.fileList.length > 0) {
      setUploadButtonDisable(false);
    } else {
      setUploadButtonDisable(true);
    }

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUploadChange = (info) => {
    // Check if the file upload is done
    if (info.file.status === "done") {
      // setUploadButtonDisable(false)
    }
  };

  const uploadButtonClick = () => {
    setFileUploaded(true);
  };

  const uploadAnotherClick = () => {
    setFileUploaded(false);
    setUploadButtonDisable(true)
  };

  return (
    <Layout>
      <Card>
        {!fileUploaded ? (
          <Form
            layout="vertical"
            name="advanced_search"
            className="ant-advanced-search-form"
          >
            <div className="container">
              <Flex
                className="py-2"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">Upload Member Sheet</h2>
                <div className="mb-3">
                  <Button
                    type="primary"
                    disabled={uploadButtonDisable}
                    onClick={() => uploadButtonClick()}
                  >
                    Upload
                  </Button>
                </div>
              </Flex>
            </div>
            <div className="container mt-0">
              <Form.Item label="Please upload member's excel sheet to insert member's data">
                <Form.Item
                  name="dragger"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  noStyle
                >
                  <Upload.Dragger
                    name="files"
                    multiple={false}
                    action="/upload.do"
                    onChange={handleUploadChange}
                    accept=".xlxs,.xml,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,vnd.openxmlformats-officedocument.wordprocessingml.document"
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single upload.
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <Result
            status="success"
            title="File Uploaded Successfully!"
            subTitle="The member data has been uploaded and processed."
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => getToMembers()}
              >
                Go To Members
              </Button>,
              <Button key="uploadAgain" onClick={() => uploadAnotherClick()}>
                Upload Another File
              </Button>,
            ]}
          />
        )}
      </Card>
    </Layout>
  );
};

export default UploadMembers;
