import { createSlice } from "@reduxjs/toolkit";
import { fetchVisitedMembers } from "../../api/fetchVisitedMember";

const initialState = {
  data: [],
  page: 1,
  limit: 5,
  total: 0,
  isLoading: false,
  error: "",
};
export const visitedMemberSlice = createSlice({
  name: "visitedMembers",
  initialState,
  reducers: {
    clearVisitedMembers: (state) => {
      state.data = null;
      state.page = 1;
      state.total = 0;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVisitedMembers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVisitedMembers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload.Data;
        state.page = action.payload.page;
        state.total = action.payload.Total;
      })
      .addCase(fetchVisitedMembers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch data";
      });
  },
});

export const { clearVisitedMembers } = visitedMemberSlice.actions;

export default visitedMemberSlice.reducer;
