import { createSlice } from "@reduxjs/toolkit";
import { deleteStaff } from "../../api/DeleteStaff";

const initialState = {
  data: null,
  isDeletingLoading: false,
};

export const deleteStaffSlice = createSlice({
  name: "deleteStaff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteStaff.pending, (state) => {
        state.isDeletingLoading = true;
      })
      .addCase(deleteStaff.fulfilled, (state, action) => {
        state.isDeletingLoading = false;
        state.data = action.payload;
      })
      .addCase(deleteStaff.rejected, (state) => {
        state.isDeletingLoading = false;
        state.data = null;
      });
  },
});

export default deleteStaffSlice.reducer;
