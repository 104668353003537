import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const updateActiveDeactiveMember = createAsyncThunk(
  "members/updateActiveDeactiveMember",
  async (id, thunkAPI) => {
    try {
      const response = await instance.put(
        `/member/${id}/active-deactive`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to update member"
      );
    }
  }
);
