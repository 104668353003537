import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const fetchGallery = createAsyncThunk(
  "gallery/fetchGallery",
  async (params, thunkAPI) => {
    try {
      const response = await instance.get("/gallery", { params });

      return {
        data: response.data.Data,
        total: response.data.Total,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to fetch event data"
      );
    }
  }
);
