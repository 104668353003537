import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  LogoutOutlined,
  LockOutlined,
} from "@ant-design/icons";
import Utils from "../utils";
import { getUserData, removeUserData } from "../utils/token";

export default function NavProfile() {
  const navigate = useNavigate();
  const userData = getUserData();
  const menuItem = [
    // {
    // 	title: "Edit Profile",
    // 	icon: <EditOutlined /> ,
    // 	path: "/"
    // },

    // {
    // 	title: "Account Setting",
    // 	icon: <SettingOutlined />,
    // 	path: "/"
    // },
    // {
    // 	title: "Billing",
    // 	icon: <ShopOutlined /> ,
    // 	path: "/"
    // },
    // {
    // 	title: "Help Center",
    // 	icon: <QuestionCircleOutlined />,
    // 	path: "/"
    // }
    // ,
    {
      title: "Change Password",
      icon: <LockOutlined />,
      action: () => {
        navigate("/change-password");
      },
    },
    {
      title: "Sign Out",
      icon: <LogoutOutlined />,
      action: () => {
        removeUserData();
        navigate("/login");
      },
    },
  ];
  const handleClick = (item) => {
    item.action();
  };
  const profileMenu = (
    <div className="nav-dropdown mt-2">
      <Menu>
        {menuItem.map((el, i) => {
          return (
            <Menu.Item key={i} className="py-2" onClick={() => handleClick(el)}>
              <Link to={el.path}>
                {el.icon}
                <span className="font-weight-normal ml-2">{el.title}</span>
              </Link>
            </Menu.Item>
          );
        })}
        {/* <Menu.Item key={menuItem.length + 1} onClick={e => (e)}>
              <span>
                <LogoutOutlined className="mr-3"/>
                <span className="font-weight-normal">Sign Out</span>
              </span>
            </Menu.Item> */}
      </Menu>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <div className="nav-profile-header d-flex m-auto cursor-poniter">
        <div className="d-flex">
          <Avatar
            size={45}
            className="font-size-xl"
            style={{ backgroundColor: "#fa8c16" }}
          >
            {Utils.getNameInitial(`${userData.firstName} ${userData.lastName}`)}
          </Avatar>
          <div className="pl-3">
            <h4 className="mb-0">{`${userData.firstName} ${userData.lastName}`}</h4>
            <span className="text-muted">
              Club Oliv {userData.backyardUserRole}
            </span>
          </div>
        </div>
      </div>
    </Dropdown>
  );
}
