import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Form, Button, Card, message, Upload } from "antd";
import Flex from "../../Components/Ui/Flex";
import Layout from "../../Layouts/Layout";
import GeneralForm from "../../Components/member/GeneralForm";
import FamilyMemberForm from "../../Components/member/FamilyMemberForm";
import AdminOnlyForm from "../../Components/member/AdminOnlyForm";
import { calculateAge } from "../../utils/date";
import { useDispatch, useSelector } from "react-redux";
import { fetchPincodeDetails } from "../../store/api/fetchPostal";
import { debounce } from "lodash";
import { addMember } from "../../store/api/AddMember";
import { getBase64 } from "../../utils/file";

const { TabPane } = Tabs;

const AddMembers = () => {
  const [form] = Form.useForm();
  const [sameAsPostal, setSameAsPostal] = useState(true);
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.pincodeReducer);
  const { data: upcomingData } = useSelector(
    (state) => state.upcomingCodeReducer
  );

  const { isAddingLoading } = useSelector((state) => state.addMemberReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (upcomingData) {
      form.setFieldValue("membershipCode", upcomingData);
    }
  }, [upcomingData]);

  useEffect(() => {
    const { District: city, State: state } = data;
    if (data && sameAsPostal) {
      form.setFieldsValue({ city, state });
      form.setFieldsValue({
        permanentAddressCity: city,
        permanentAddressState: state,
      });
    } else if (data && !sameAsPostal) {
      form.setFieldsValue({
        permanentAddressCity: city,
        permanentAddressState: state,
      });
    }
  }, [data]);

  const beforeUpload = (file) => {
    const isValidFileType = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/jpg",
    ].includes(file.type);

    if (!isValidFileType) {
      message.error("You can only upload JPG, PNG, or WEBP files!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = async ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[fileList.length - 1];
      const base64 = await getBase64(file.originFileObj);

      form.setFieldsValue({
        photo: base64,
      });
    }
  };

  const handleRemove = () => {
    form.setFieldsValue({
      photo: null,
    });
  };

  const dispatchDebouncedPincodeDetails = debounce((pincode) => {
    dispatch(fetchPincodeDetails({ pincode_id: pincode }));
  }, 1000);

  const onDiscard = () => {
    navigate("/members");
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  const onFinish = (values) => {
    const {
      membershipCode,
      introducerCode,
      seconderCode,
      registrationDate,
      guestMemberCardNumber,
      membershipRemarks,
      members = [],
      ...rest
    } = values;
    const newValues = {
      membershipTypeId: "1",
      membershipCode,
      introducerCode,
      seconderCode,
      registrationDate,
      guestMemberCardNumber,
      membershipRemarks,
      members: [
        {
          ...rest,
          memberRelationId: "1",
          sameAsPostalAddress: sameAsPostal ? "1" : "0",
        },
        ...members,
      ].map((m) => {
        if (m.email) {
          return { ...m, email: m.email.toLowerCase() };
        } else {
          return m;
        }
      }),
    };
    dispatch(addMember(newValues))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("Member successfully added!");
          navigate("/members");
        } else {
          message.error(response.Message);
        }
      })
      .catch((error) => {
        message.error("Failed to add member. Please try again.");
        console.error("Add member error:", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const onFormValuesChange = (changedValues) => {
    const { firstName, middelName, lastName } = form.getFieldsValue([
      "firstName",
      "middelName",
      "lastName",
    ]);
    if (changedValues.dateOfBirth) {
      const age = calculateAge(changedValues.dateOfBirth);
      form.setFieldsValue({ age });
    }

    if (changedValues.pinCode) {
      const pincode = changedValues.pinCode;
      dispatchDebouncedPincodeDetails(pincode);
    }

    if (!sameAsPostal && changedValues.permanentAddressPinCode) {
      const pincode = changedValues.permanentAddressPinCode;
      dispatchDebouncedPincodeDetails(pincode);
    }
    if (sameAsPostal) {
      const { postalAddress, city, state, pinCode } = form.getFieldsValue([
        "postalAddress",
        "city",
        "state",
        "pinCode",
      ]);

      form.setFieldsValue({
        permanentAddress: postalAddress,
        permanentAddressCity: city,
        permanentAddressState: state,
        permanentAddressPinCode: pinCode,
      });
    }

    if (
      changedValues.firstName ||
      changedValues.middelName ||
      changedValues.lastName
    ) {
      const members = form.getFieldValue("members") || [];

      const updatedFamilyMembers = members.map((member) => {
        const updatedMember = { ...member };

        if (member?.memberRelationId) {
          const relation = member.memberRelationId;

          switch (relation) {
            case "2":
              updatedMember.firstName = "";
              updatedMember.middelName = "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = "";
              break;
            case "3":
              updatedMember.firstName = "";
              updatedMember.lastName = lastName || "";
              updatedMember.middelName = firstName || "";
              updatedMember.gender = "0";
              break;
            case "4":
              updatedMember.firstName = "";
              updatedMember.middelName = firstName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = "1";
              break;
            case "5":
              updatedMember.firstName = middelName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.middelName = "";
              updatedMember.gender = "0";
              break;
            case "6":
              updatedMember.firstName = "";
              updatedMember.middelName = middelName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = "1";
              break;
            default:
              break;
          }
        }

        return updatedMember;
      });

      form.setFieldsValue({ members: updatedFamilyMembers });
    }

    if (changedValues.members) {
      const members = form.getFieldValue("members") || [];
      const changedFamilyMembers = changedValues.members;

      const updatedFamilyMembers = members.map((member, index) => {
        const updatedMember = { ...member };

        if (changedFamilyMembers[index]?.memberRelationId) {
          const relation = changedFamilyMembers[index].memberRelationId;

          switch (relation) {
            case "2":
              updatedMember.firstName = "";
              updatedMember.middelName = "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = "";
              break;
            case "3":
              updatedMember.firstName = "";
              updatedMember.lastName = lastName || "";
              updatedMember.middelName = firstName || "";
              updatedMember.gender = "0";
              break;
            case "4":
              updatedMember.firstName = "";
              updatedMember.middelName = firstName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = "1";
              break;
            case "5":
              updatedMember.firstName = middelName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.middelName = "";
              updatedMember.gender = "0";
              break;
            case "6":
              updatedMember.firstName = "";
              updatedMember.middelName = middelName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = "1";
              break;
            default:
              break;
          }
        }

        return updatedMember;
      });

      form.setFieldsValue({ members: updatedFamilyMembers });
    }
  };

  const switchOnChange = (checked) => {
    setSameAsPostal(checked);
    if (checked) {
      const { postalAddress, city, state, pinCode } = form.getFieldsValue([
        "postalAddress",
        "city",
        "state",
        "pinCode",
      ]);

      form.setFieldsValue({
        permanentAddress: postalAddress,
        permanentAddressCity: city,
        permanentAddressState: state,
        permanentAddressPinCode: pinCode,
      });
    } else {
      form.setFieldsValue({
        permanentAddress: "",
        permanentAddressState: "",
        permanentAddressCity: "",
        permanentAddressPinCode: "",
      });
    }
  };

  return (
    <>
      <Layout>
        <Card>
          <Form
            form={form}
            layout="vertical"
            onValuesChange={onFormValuesChange}
            name="advanced_search"
            className="ant-advanced-search-form"
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="none"
          >
            <div className="container">
              <Flex
                className="py-2"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">Add New Member</h2>
                <div className="mb-3">
                  <Button className="mr-2" onClick={() => onDiscard()}>
                    Discard
                  </Button>
                  <Button
                    type="primary"
                    loading={isAddingLoading}
                    htmlType="submit"
                  >
                    Add
                  </Button>
                </div>
              </Flex>
            </div>
            <div className="container mt-0">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Main Member Basic" key="1">
                  <GeneralForm
                    sameAsPostal={sameAsPostal}
                    switchOnChange={switchOnChange}
                    beforeUpload={beforeUpload}
                    handleChange={handleChange}
                    handleRemove={handleRemove}
                    form={form}
                  ></GeneralForm>
                </TabPane>
                <TabPane tab="Family members" key="3">
                  <FamilyMemberForm form={form}></FamilyMemberForm>
                </TabPane>
                <TabPane tab="Admin Only" key="2">
                  <AdminOnlyForm form={form}></AdminOnlyForm>
                </TabPane>
              </Tabs>
            </div>
          </Form>
        </Card>
      </Layout>
    </>
  );
};

export default AddMembers;
