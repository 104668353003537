import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const fetchMembershipToken = createAsyncThunk(
  "membership/fetchMembershipToken",
  async ({ id }, thunkAPI) => {
    try {
      const response = await instance.get(`/membership/token/${id}`);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to fetch membership token"
      );
    }
  }
);
