import React, { useEffect, useState } from "react";
import Layout from "../../Layouts/Layout";
import { Button, Card, Col, Form, Input, Row, Spin, message } from "antd";
import Flex from "../../Components/Ui/Flex";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PicturesWall from "../../Components/gallery/GalleryWall";

import { EditOutlined, LeftCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { fetchGalleryById } from "../../store/api/fetchSingleGallery";
import { updateGallery } from "../../store/api/UpdateGallery";

const EditGallery = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: gallery, isLoading } = useSelector(
    (state) => state.singleGalleryReducer
  );
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchGalleryById({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (gallery) {
      form.setFieldsValue(gallery);
    }
  }, [gallery]);

  const onDiscard = () => {
    form.resetFields();
    navigate("/gallery");
  };

  const onSave = () => {
    form
      .validateFields(["name"])
      .then((values) => {
        const formData = new FormData();
        formData.append("id", gallery.id);
        formData.append("name", values.name);
        dispatch(updateGallery(formData))
          .unwrap()
          .then((response) => {
            if (response.Status) {
              message.success("Gallery name updated successfully!");
              setIsEditing(false);
            } else {
              message.error(response.Message);
            }
          })
          .catch((error) => {
            message.error("Failed to update gallery name!");
          });
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const toggleEditMode = () => {
    if (isEditing) {
      onSave();
    } else {
      setIsEditing(true);
    }
  };

  return (
    <Layout>
      <Card>
        {isLoading || !gallery ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Form
            form={form}
            layout="vertical"
            name="edit_gallery_form"
            initialValues={{
              ...gallery,
            }}
          >
            <div className="container">
              <Flex
                className="py-2"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">Update Gallery</h2>
                <div className="mb-3">
                  <Button
                    type="secondary"
                    className="mr-2"
                    onClick={() => onDiscard()}
                  >
                    <LeftCircleOutlined />
                    BACK
                  </Button>
                </div>
              </Flex>
            </div>
            <Col xs={24}>
              <Card title="Gallery Info">
                <Row gutter={[16, 16]}>
                  <Col span={8} key={1} className="px-1">
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input the gallery name!",
                        },
                      ]}
                      style={{ display: "flex" }}
                    >
                      <Input
                        placeholder="Name"
                        autoComplete="none"
                        disabled={!isEditing}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={1}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isEditing ? (
                      <Button
                        icon={<SaveOutlined />}
                        onClick={toggleEditMode}
                        style={{ marginTop: "6px" }}
                      />
                    ) : (
                      <Button
                        icon={<EditOutlined />}
                        onClick={toggleEditMode}
                        style={{ marginTop: "6px" }}
                      />
                    )}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card title="Gallery Images">
                      <PicturesWall gallery={gallery} />
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Form>
        )}
      </Card>
    </Layout>
  );
};

export default EditGallery;
