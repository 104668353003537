import React from "react";
import { Avatar } from "antd";
import Utils from "../../utils";

const CustomAvatar = ({
  name,
  surname,
  profile,
  onClick,
  size = 30,
  className,
  ...props
}) => {
  const initials = Utils.getNameInitial(
    `${name?.[0] || ""} ${surname?.[0] || ""}`.toUpperCase()
  );

  return (
    <Avatar
      size={size}
      src={profile || undefined}
      alt={`${name} ${surname}`}
      className={className}
      style={{
        color: "#f56a00",
        backgroundColor: "#fde3cf",
        ...props.style,
      }}
      onClick={onClick}
      {...props}
    >
      {!profile && initials}
    </Avatar>
  );
};

export default CustomAvatar;
