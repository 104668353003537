import { createSlice } from "@reduxjs/toolkit";
import { exportMembers } from "../../api/exportMembers";
import { message } from "antd";

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
  error: "",
};
export const exportMembersSlice = createSlice({
  name: "exportMembers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(exportMembers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(exportMembers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload.data;
        if (action.payload.data.length === 0) {
          message.error("No data available for export.");
        }
        state.total = action.payload.total;
      })
      .addCase(exportMembers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch data";
      });
  },
});

export default exportMembersSlice.reducer;
