import React from "react";

import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Upload,
  Button,
  Select,
  Space,
  Image,
} from "antd";
import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
const { Option } = Select;

export default function GeneralForm(props) {
  const handleUploadRequest = ({ file, onSuccess, onError }) => {
    onSuccess();
  };

  return (
    <Row>
      <Col xs={24}>
        <Card title="Event Details">
          <Row>
            <Col span={24} key={1} className="px-1">
              <Form.Item
                label="Event Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter the event name" },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} key={1} className="px-1">
              <Form.Item
                label="Event Start Date"
                name="startDate"
                rules={[
                  { required: true, message: "Please select the start date" },
                ]}
              >
                <Input type="date" placeholder="" />
              </Form.Item>
            </Col>
            <Col span={8} key={1} className="px-1">
              <Form.Item
                label="Event End Date"
                name="endDate"
                rules={[
                  { required: true, message: "Please select the end date" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("startDate") <= value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("End date cannot be before start date")
                      );
                    },
                  }),
                ]}
              >
                <Input type="date" placeholder="" />
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item
                label="Event Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Please enter the status",
                  },
                ]}
              >
                <Select className="w-100">
                  <Option value="1">Upcoming</Option>
                  <Option value="2">Running</Option>
                  <Option value="3">Complete</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} key={1} className="px-1">
              <Form.Item
                label="Registration Link"
                name="registrationLink"
                rules={[
                  {
                    type: "url",
                    message: "Please enter a valid URL",
                  },
                ]}
              >
                <Input placeholder="https://example.com" />
              </Form.Item>
            </Col>
            <Col span={12} key={1} className="px-1">
              <Form.Item
                label="Social Media Link"
                name="socialMediaLink"
                rules={[
                  {
                    type: "url",
                    message: "Please enter a valid URL",
                  },
                ]}
              >
                <Input placeholder="https://example.com" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12} key={1} className="px-1">
              <Form.Item
                name="featuredImage"
                label="Event Featured Image"
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Please upload an event featured image",
                  },
                ]}
              >
                <Space>
                  <Upload
                    name="featuredImage"
                    listType="picture"
                    beforeUpload={props.beforeUpload}
                    maxCount={1}
                    accept=".jpg,.jpeg,.png,.webp"
                    customRequest={handleUploadRequest}
                    onChange={(info) =>
                      props.handleChange(info, "featuredImage")
                    }
                    onRemove={() => props.handleRemove("featuredImage")}
                  >
                    <Button>
                      <UploadOutlined />
                      Click to upload
                    </Button>
                  </Upload>
                  {props.form.getFieldValue("featuredImage") && (
                    <Image
                      height={48}
                      width={48}
                      src={props.form.getFieldValue("featuredImage")}
                      preview={{ mask: <EyeOutlined /> }}
                    />
                  )}
                </Space>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} key={1} className="px-1">
              <Form.Item
                name="bannerImage"
                label="Event Banner Image"
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Please upload an event banner image",
                  },
                ]}
              >
                <Space>
                  <Upload
                    name="bannerImage"
                    listType="picture"
                    beforeUpload={props.beforeUpload}
                    maxCount={1}
                    accept=".jpg,.jpeg,.png,.webp"
                    customRequest={handleUploadRequest}
                    onChange={(info) => props.handleChange(info, "bannerImage")}
                    onRemove={() => props.handleRemove("bannerImage")}
                  >
                    <Button>
                      <UploadOutlined />
                      Click to upload
                    </Button>
                  </Upload>
                  {props.form.getFieldValue("bannerImage") && (
                    <Image
                      height={48}
                      width={48}
                      src={props.form.getFieldValue("bannerImage")}
                      preview={{ mask: <EyeOutlined /> }}
                    />
                  )}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Col>
      {/* <Col xs={24} sm={24} md={7}>
        <Card title="Organization">
          <Form.Item label="Type">
            <Select className="w-100" placeholder="Type">
              <Option value="Option 1">Option 1</Option>
              <Option value="Option 2">Option 2</Option>
              <Option value="Option 3">Option 3</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Tags">
            <Select mode="tags" style={{ width: "100%" }} placeholder="Tags">
              <Option value="Tag 1">Tag 1</Option>
              <Option value="Tag 2">Tag 2</Option>
              <Option value="Tag 3">Tag 3</Option>
            </Select>
          </Form.Item>
        </Card>
      </Col> */}
    </Row>
  );
}
