import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const fetchGalleryById = createAsyncThunk(
  "gallery/fetchGalleryById",
  async ({ id }, thunkAPI) => {
    try {
      const response = await instance.get(`/gallery/${id}`);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to fetch gallery"
      );
    }
  }
);
