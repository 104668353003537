import React from "react";
import { Card, Button, Form, message } from "antd";

import ChangePasswordForm from "../Components/change-password/ChangePasswordForm";
import Layout from "../Layouts/Layout";
import Flex from "../Components/Ui/Flex";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, removeUserData } from "../utils/token";
import { changePassword } from "../store/api/ChangePassword";

const ChangePassword = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const user = getUserData();

  const { isLoading } = useSelector((state) => state.changePasswordReducer);
  const onDiscard = () => {
    navigate(-1);
  };

  const onFinish = (values) => {
    const { currentPassword, newPassword } = values;
    const data = { id: user.id, currentPassword, newPassword };

    dispatch(changePassword(data))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("Password changed successfully!");
          navigate(-1);
        } else {
          message.error(response.Message);
        }
      })
      .catch((err) => {
        message.error(err || "Password change failed!");
      });
  };
  return (
    <Layout>
      <Card>
        <Form
          name="changePasswordForm"
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <div className="container">
            <Flex
              className="py-2"
              mobileFlex={false}
              justifyContent="between"
              alignItems="center"
            >
              <h2 className="mb-3">Change Password</h2>
              <div className="mb-3">
                <Button className="mr-2" onClick={() => onDiscard()}>
                  Discard
                </Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Change Password
                </Button>
              </div>
            </Flex>
          </div>
          <div className="container mt-0">
            <ChangePasswordForm {...props} />
          </div>
        </Form>
      </Card>
    </Layout>
  );
};

export default ChangePassword;
