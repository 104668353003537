import {
  Tooltip,
  Col,
  Divider,
  Drawer,
  Tag,
  Row,
  Button,
  Popconfirm,
  message,
  Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFamilyMembers } from "../../store/api/fetchFamilyMembers";
import { calculateAge, roleMap, formatDate } from "../../utils/date";
import CustomAvatar from "../common/Avatar";
import { updateActiveDeactiveMember } from "./../../store/api/UpdateActiveDeactiveMember/index";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const UserView = (props) => {
  const { data, visible, close } = props;
  const [selectedId, setSelectedId] = useState(null);

  const { data: familyMembers, isLoading } = useSelector(
    (state) => state.familyMembersReducer
  );
  const { isUpdatingLoading } = useSelector(
    (state) => state.updateActiveDeactiveMemberReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.id) {
      dispatch(fetchFamilyMembers({ membership_id: data.id }));
    }
  }, [data.id, dispatch]);

  useEffect(() => {
    if (familyMembers?.members && familyMembers.members.length > 0) {
      if (
        !(selectedId && familyMembers.members.some((m) => m.id === selectedId))
      ) {
        setSelectedId(
          familyMembers.members.find((m) => m?.memberRelationId === "1")?.id ||
            ""
        );
      }
    }
  }, [familyMembers]);

  const handleAvatarClick = (id) => {
    setSelectedId(id);
  };

  function confirm(e) {
    dispatch(updateActiveDeactiveMember(e))
      .then(() => {
        message.success("Status updated successfully");
        dispatch(fetchFamilyMembers({ membership_id: data.id }));
      })
      .catch((error) => {
        message.error("Failed to update : " + error.message);
      });
  }

  function cancel(e) {}

  const selectedMember = familyMembers?.members?.find(
    (member) => member?.id === selectedId
  );

  return (
    <Drawer
      width={640}
      motion={null}
      placement="right"
      closable={false}
      onClose={close}
      open={visible}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        familyMembers?.members &&
        familyMembers.members.length > 0 && (
          <>
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div>
                <Tooltip
                  title={`${selectedMember?.firstName} ${selectedMember?.middelName} ${selectedMember?.lastName}`}
                >
                  <div className="mr-2" style={{ display: "inline-table" }}>
                    <CustomAvatar
                      name={selectedMember?.firstName}
                      surname={selectedMember?.lastName}
                      profile={selectedMember?.photo}
                      size={80}
                      style={{
                        color: "#f56a00",
                        backgroundColor: "#fde3cf",
                        marginRight: "0px",
                        fontSize: "40px",
                        display: "inline-flex",
                      }}
                    />
                    <div className="text-center">
                      {roleMap[selectedMember?.memberRelationId] || "Unknown"}
                    </div>
                  </div>
                </Tooltip>
                {familyMembers.members
                  .filter((member) => member.id !== selectedId)
                  .map((member, index) => (
                    <Tooltip
                      key={index}
                      title={`${member?.firstName} ${member?.middelName} ${member?.lastName}`}
                    >
                      <div className="mx-2" style={{ display: "inline-table" }}>
                        <CustomAvatar
                          name={member?.firstName}
                          surname={member?.lastName}
                          profile={member?.photo}
                          className="cursor-pointer"
                          size="40"
                          onClick={() => handleAvatarClick(member.id)}
                        />
                        <div className="text-center">
                          {roleMap[member?.memberRelationId] || "Unknown"}
                        </div>
                      </div>
                    </Tooltip>
                  ))}
              </div>
              <div>
                <Popconfirm
                  placement="bottomLeft"
                  title={`Are you sure you want to ${
                    selectedMember?.isActive === "1" ? "suspend" : "activate"
                  } ${selectedMember?.firstName} ${
                    selectedMember?.middelName
                  } ${selectedMember?.lastName} ${
                    selectedMember?.memberRelationId === "1"
                      ? "with whole family"
                      : ""
                  }?`}
                  onConfirm={() => confirm(selectedId)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    {...(selectedMember?.isActive === "1" && { danger: true })}
                    loading={isUpdatingLoading}
                  >
                    Want to
                    {selectedMember?.isActive === "1"
                      ? " Suspend"
                      : " Activate"}{" "}
                    ?
                  </Button>
                </Popconfirm>
              </div>
            </div>

            <p className="site-description-item-profile-p mt-3">
              Personal Details
              {selectedMember?.isActive && (
                <Tag
                  className="ml-2"
                  color={selectedMember?.isActive === "1" ? "#87d068" : "red"}
                >
                  {selectedMember?.isActive === "1" ? "Active" : "Suspended"}
                </Tag>
              )}
            </p>

            <Row className="pb-3">
              <Col span={12}>
                <DescriptionItem
                  title="Full Name"
                  content={`${selectedMember?.firstName} ${selectedMember?.middelName} ${selectedMember?.lastName}`}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Role"
                  content={
                    roleMap[selectedMember?.memberRelationId] || "Unknown"
                  }
                />
              </Col>
            </Row>
            <Row className="pb-3">
              <Col span={12}>
                <DescriptionItem
                  title="Phone"
                  content={selectedMember?.mobileNumber || "-"}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Email"
                  content={selectedMember?.email || "-"}
                />
              </Col>
            </Row>
            <Row className="pb-3">
              <Col span={12}>
                <DescriptionItem
                  title="Card No"
                  content={selectedMember?.cardNo || "-"}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Internal Code"
                  content={selectedMember?.internalCardNumber}
                />
              </Col>
            </Row>
            <Row className="pb-3">
              <Col span={12}>
                <DescriptionItem
                  title="Birthday"
                  content={
                    selectedMember?.dateOfBirth
                      ? formatDate(selectedMember?.dateOfBirth)
                      : "-"
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Age"
                  content={
                    selectedMember?.dateOfBirth
                      ? calculateAge(selectedMember?.dateOfBirth)
                      : "-"
                  }
                />
              </Col>
            </Row>
            <Row className="pb-3">
              <Col span={12}>
                <DescriptionItem
                  title="Secret Code"
                  content={selectedMember?.secretCode || "-"}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Pancard No"
                  content={selectedMember?.panNo || "-"}
                />
              </Col>
            </Row>
            <Divider />
            <p className="site-description-item-profile-p">
              Membership Details
            </p>
            <Row className="pb-3">
              <Col span={12}>
                <DescriptionItem
                  title="Membership ID"
                  content={familyMembers?.membershipCode || "-"}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Membership Purchase"
                  content={
                    <>
                      {formatDate(familyMembers.registrationDate) || "-"}
                      <Tag color="blue" style={{ marginLeft: 8 }}>
                        {(calculateAge(familyMembers?.registrationDate) || 0) ==
                        0
                          ? "This Year"
                          : calculateAge(familyMembers?.registrationDate) == 1
                          ? `${calculateAge(
                              familyMembers?.registrationDate
                            )} Year`
                          : `${calculateAge(
                              familyMembers?.registrationDate
                            )} Years`}
                      </Tag>
                    </>
                  }
                />
              </Col>
            </Row>
            <Row className="pb-3">
              <Col span={12}>
                <DescriptionItem
                  title="Main Introducer"
                  content={familyMembers?.introducerCode || "-"}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Sub Introducer"
                  content={familyMembers?.seconderCode || "-"}
                />
              </Col>
            </Row>
            <Row className="pb-3">
              <Col span={24}>
                <DescriptionItem
                  title="Mailing Address"
                  content={selectedMember?.postalAddress || "-"}
                />
              </Col>
            </Row>
            <Row className="pb-3">
              <Col span={24}>
                <DescriptionItem
                  title="Member Remarks"
                  content={familyMembers?.membershipRemarks || "-"}
                />
              </Col>
            </Row>
          </>
        )
      )}
    </Drawer>
  );
};

export default UserView;
