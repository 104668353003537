import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const addGallery = createAsyncThunk(
  "events/addGallery",
  async (data, thunkAPI) => {
    try {
      const response = await instance.post("/gallery", data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to add gallery"
      );
    }
  }
);
