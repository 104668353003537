import { createSlice } from "@reduxjs/toolkit";
import { fetchFamilyMembers } from "../../api/fetchFamilyMembers";

const initialState = {
  data: null,
  isLoading: false,
  error: "",
};

export const familyMembersSlice = createSlice({
  name: "familyMembers",
  initialState,
  reducers: {
    clearFamilyMembersData: (state) => {
      state.data = null;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFamilyMembers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFamilyMembers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload.Data;
      })
      .addCase(fetchFamilyMembers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch family members data";
      });
  },
});

export const { clearFamilyMembersData } = familyMembersSlice.actions;

export default familyMembersSlice.reducer;
