import { createSlice } from "@reduxjs/toolkit";
import { updateActiveDeactiveMember } from "../../api/UpdateActiveDeactiveMember";

const initialState = {
  data: null,
  isUpdatingLoading: false,
};

export const updateActiveDeactiveMemberSlice = createSlice({
  name: "updateActiveDeactiveMember",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateActiveDeactiveMember.pending, (state) => {
        state.isUpdatingLoading = true;
      })
      .addCase(updateActiveDeactiveMember.fulfilled, (state, action) => {
        state.isUpdatingLoading = false;
        state.data = action.payload;
      })
      .addCase(updateActiveDeactiveMember.rejected, (state) => {
        state.isUpdatingLoading = false;
        state.data = null;
      });
  },
});

export default updateActiveDeactiveMemberSlice.reducer;
