import { createSlice } from "@reduxjs/toolkit";
import { fetchSignUp } from "../../api/fetchSignUp";
import { storeUserData } from "../../../utils/token";

const initialState = {
  data: null,
  isLoading: false,
  error: "",
};

export const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSignUp.pending, (state) => {
        state.isLoading = true;
        state.error = ""; 
      })
      .addCase(fetchSignUp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload;
        storeUserData(action.payload);
      })
      .addCase(fetchSignUp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default signUpSlice.reducer;
