import React from "react";

import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Upload,
  Button,
  Select,
  Space,
  Image,
} from "antd";
import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { getUserData } from "../../utils/token";
const { Option } = Select;

export default function StaffForm(props) {
  const handleUploadRequest = ({ file, onSuccess, onError }) => {
    onSuccess();
  };
  const userData = getUserData();
  return (
    <Row>
      <Col xs={24}>
        <Card title="Staff Details">
          <Row>
            <Col span={12} key={1} className="px-1">
              <Form.Item
                label="Staff First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the staff first name",
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12} key={1} className="px-1">
              <Form.Item
                label="Staff Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the staff last name",
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} key={1} className="px-1">
              <Form.Item
                label="Staff Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter the staff email!",
                  },
                  {
                    type: "email",
                    message: "The input is not a valid email!",
                  },
                ]}
              >
                <Input type="email" placeholder="Staff Email" />
              </Form.Item>
            </Col>
            <Col span={8} key={1} className="px-1">
              <Form.Item
                label="Mobile"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your mobile number!",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Mobile number must be 10 digits long!",
                  },
                ]}
              >
                <Input type="tel" placeholder="Mobile*" />
              </Form.Item>
            </Col>
            <Col span={8} key={1} className="px-1">
              <Form.Item
                label="Role"
                name="backyardUserRoleId"
                rules={[
                  {
                    required: true,
                    message: "Please select role!",
                  },
                ]}
              >
                <Select className="w-100 mb-2">
                  <Option value="1">Super Admin</Option>
                  <Option value="2">Admin</Option>
                  <Option value="3">Staff</Option>
                  {userData.backyardUserRoleId === "4" && (
                    <Option value="4">Developer</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} key={1} className="px-1">
              <Form.Item label="Address" name="address">
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} key={1} className="px-1">
              <Form.Item
                name="profilePicture"
                label="Profile Picture"
                valuePropName="fileList"
              >
                <Space>
                  <Upload
                    name="profilePicture"
                    listType="picture"
                    beforeUpload={props.beforeUpload}
                    maxCount={1}
                    accept=".jpg,.jpeg,.png,.webp"
                    customRequest={handleUploadRequest}
                    onChange={props.handleChange}
                    onRemove={props.handleRemove}
                  >
                    <Button>
                      <UploadOutlined />
                      Click to upload
                    </Button>
                  </Upload>
                  {props.form.getFieldValue("profilePicture") && (
                    <Image
                      height={48}
                      width={48}
                      src={props.form.getFieldValue("profilePicture")}
                      preview={{ mask: <EyeOutlined /> }}
                    />
                  )}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Col>
      {/* <Col xs={24} sm={24} md={7}>
        <Card title="Organization">
          <Form.Item label="Type">
            <Select className="w-100" placeholder="Type">
              <Option value="Option 1">Option 1</Option>
              <Option value="Option 2">Option 2</Option>
              <Option value="Option 3">Option 3</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Tags">
            <Select mode="tags" style={{ width: "100%" }} placeholder="Tags">
              <Option value="Tag 1">Tag 1</Option>
              <Option value="Tag 2">Tag 2</Option>
              <Option value="Tag 3">Tag 3</Option>
            </Select>
          </Form.Item>
        </Card>
      </Col> */}
    </Row>
  );
}
