import React from "react";
import Layout from "../Layouts/Layout";
import { Button, Card, Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../Components/Ui/Flex";
import PushNotificationForm from "../Components/push-notification/PushNotificationForm";
import { pushNotification } from "../store/api/pushNotification";

const PushNotification = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { isLoading } = useSelector((state) => state.pushNotificationReducer);

  const onFinish = (values) => {
    let userRoleType = [];

    if (values.staff) {
      userRoleType.push(1);
    }

    if (values.allMembers) {
      userRoleType.push(2);
    }

    if (userRoleType.length === 0) {
      message.error("Please select at least one role: Staff or All Members.");
      return;
    }

    const userRoleTypeString = userRoleType.join(",");

    const transformedData = {
      title: values.title,
      message: values.message,
      userRoleType: userRoleTypeString,
    };

    dispatch(pushNotification(transformedData))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("Pushed Notification successfully!");
          form.resetFields();
        } else {
          message.error(response.Message);
        }
      })
      .catch((err) => {
        message.error(err || "Pushing notification failed!");
      });
  };
  return (
    <Layout>
      <Card>
        <Form
          name="changePasswordForm"
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <div className="container">
            <Flex
              className="py-2"
              mobileFlex={false}
              justifyContent="between"
              alignItems="center"
            >
              <h2 className="mb-3">Push Notification</h2>
              <div className="mb-3">
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Submit
                </Button>
              </div>
            </Flex>
          </div>
          <div className="container mt-0">
            <PushNotificationForm {...props} />
          </div>
        </Form>
      </Card>
    </Layout>
  );
};

export default PushNotification;
