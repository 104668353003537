import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const fetchAppBanners = createAsyncThunk(
  "banners/fetchAppBanners",
  async (params, thunkAPI) => {
    try {
      const response = await instance.get("/banner", {
        params: { ...params, source: "web" },
      });

      return {
        data: response.data.Data,
        total: response.data.TotalCnt,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to fetch banner data"
      );
    }
  }
);
