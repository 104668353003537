import { createSlice } from "@reduxjs/toolkit";
import { fetchPincodeDetails } from "../../api/fetchPostal";

const initialState = {
  data: [],
  isLoading: false,
  error: "",
};

export const pincodeDetailsSlice = createSlice({
  name: "pincodeDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPincodeDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPincodeDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload;
      })
      .addCase(fetchPincodeDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch pincode details";
      });
  },
});

export default pincodeDetailsSlice.reducer;
