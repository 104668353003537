import React, { useState } from "react";
import { Button, Form, Input, Divider, Alert, message } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSignUp } from "../../store/api/fetchSignUp";

export default function LoginForm(props) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => state.signUpReducer
  );
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const navigate = useNavigate();
  const initialCredential = {};

  const onLogin = async (values) => {
    try {
      await dispatch(fetchSignUp(values)).unwrap();
      message.success("Successfully logged in.")
      navigate("/members");
    } catch (error) {
      message.error(error);
    }
  };

  const onForgetPasswordClick = () => {};

  return (
    <Form
      layout="vertical"
      name="login-form"
      initialValues={initialCredential}
      onFinish={onLogin}
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: "Please input your email",
          },
          {
            type: "email",
            message: "Please enter a validate email!",
          },
        ]}
      >
        <Input prefix={<MailOutlined className="text-primary" />} />
      </Form.Item>
      <Form.Item
        name="password"
        label={
          <div
            className={`${
              showForgetPassword
                ? "d-flex justify-content-between w-100 align-items-center"
                : ""
            }`}
          >
            <span>Password</span>
            {showForgetPassword && (
              <span
                onClick={() => onForgetPasswordClick}
                className="cursor-pointer font-size-sm font-weight-normal text-muted"
              >
                Forget Password?
              </span>
            )}
          </div>
        }
        rules={[
          {
            required: true,
            message: "Please input your password",
          },
        ]}
      >
        <Input.Password prefix={<LockOutlined className="text-primary" />} />
      </Form.Item>
      <Form.Item className="pt-4">
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          Sign In
        </Button>
      </Form.Item>
    </Form>
  );
}
