import { createSlice } from "@reduxjs/toolkit";
import { fetchBannerById } from "../../api/fetchSingleBanner";

const initialState = {
  data: null,
  isLoading: false,
  error: "",
};

export const singleBannerSlice = createSlice({
  name: "single-banner",
  initialState,
  reducers: {
    clearSingleBanner: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannerById.pending, (state) => {
        state.isLoading = true;
        state.data = null;
      })
      .addCase(fetchBannerById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload.Data;
      })
      .addCase(fetchBannerById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch single banner";
      });
  },
});

export const { clearSingleBanner } = singleBannerSlice.actions;
export default singleBannerSlice.reducer;
