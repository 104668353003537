import React, { useEffect } from "react";
import Layout from "../../Layouts/Layout";
import { Button, Card, Col, Form, Input, message, Row, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../Components/Ui/Flex";
import { addBanner } from "../../store/api/addBanner";
import AppBannerForm from "../../Components/app-banner/AppBannerForm";
import { getBase64 } from "../../utils/file";

const AddBanner = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAddingLoading } = useSelector((state) => state.addBannerReducer);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onDiscard = () => {
    navigate("/app-banner");
    form.resetFields();
  };

  const onFinish = (values) => {
    const payload = { ...values, status: "1" };

    if (!payload.image) {
      delete payload.image;
    }

    dispatch(addBanner(payload))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("Banner successfully added!");
          navigate("/app-banner");
        } else {
          message.error(response.Message);
        }
      })
      .catch((error) => {
        message.error("Failed to add banner. Please try again.");
        console.error("Add banner error:", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const handleRemove = (key) => {
    form.setFieldsValue({
      [key]: null,
    });
  };
  const beforeUpload = (file) => {
    const isValidFileType = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/jpg",
    ].includes(file.type);

    if (!isValidFileType) {
      message.error("You can only upload JPG, PNG, or WEBP files!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = async ({ fileList }, key) => {
    if (fileList.length > 0) {
      const file = fileList[fileList.length - 1];

      const base64 = await getBase64(file.originFileObj);

      form.setFieldsValue({
        [key]: base64,
      });
    }
  };

  const onFormValuesChange = (changedValues) => {
    if (changedValues.image && typeof changedValues.image !== "string") {
      handleRemove();
    }
  };

  return (
    <Layout>
      <Card>
        <Form
          form={form}
          layout="vertical"
          onValuesChange={onFormValuesChange}
          name="advanced_search"
          className="ant-advanced-search-form"
          initialValues={{ }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="container">
            <Flex
              className="py-2"
              mobileFlex={false}
              justifyContent="between"
              alignItems="center"
            >
              <h2 className="mb-3">Add New Banner</h2>
              <div className="mb-3">
                <Button className="mr-2" onClick={onDiscard}>
                  Discard
                </Button>
                <Button
                  type="primary"
                  loading={isAddingLoading}
                  htmlType="submit"
                >
                  Add
                </Button>
              </div>
            </Flex>
          </div>
          <AppBannerForm
            form={form}
            beforeUpload={beforeUpload}
            handleChange={handleChange}
            handleRemove={handleRemove}
          />
        </Form>
      </Card>
    </Layout>
  );
};

export default AddBanner;
