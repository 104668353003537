import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchMemberById = createAsyncThunk(
  "members/fetchMemberById",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get("/data/members.json");

      const member = response.data.find((m) => m.id === id);

      if (!member) {
        throw new Error("Member not found");
      }

      return member;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message ||
          error.message ||
          "Failed to fetch member data"
      );
    }
  }
);
