import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Image, Input, Row, Space, Upload } from "antd";
import React from "react";

const AppBannerForm = ({
  form,
  handleChange,
  beforeUpload,
  handleRemove,
}) => {
  const handleUploadRequest = ({ file, onSuccess, onError }) => {
    onSuccess();
  };

  return (
    <Col xs={24}>
      <Card title="Banner Info">
        <Row gutter={[16, 16]}>
          <Col span={12} key={1} className="px-1">
            <Form.Item
              label="Title"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter the title name",
                },
              ]}
            >
              <Input placeholder="Title" autoComplete="none" />
            </Form.Item>
          </Col>
          <Col span={12} key={1} className="px-1">
            <Form.Item
              label="Link"
              name="link"
              rules={[
                {
                  type: "url",
                  message: "Please enter a valid URL",
                },
              ]}
            >
              <Input placeholder="https://example.com" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} key={1} className="px-1">
            <Form.Item
              name="image"
              label="Banner Image"
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: "Please upload banner image",
                },
              ]}
            >
              <Space>
                <Upload
                  name="image"
                  listType="picture"
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  accept=".jpg,.jpeg,.png,.webp"
                  customRequest={handleUploadRequest}
                  onChange={(info) => handleChange(info, "image")}
                  onRemove={() => handleRemove("image")}
                >
                  <Button>
                    <UploadOutlined />
                    Click to upload
                  </Button>
                </Upload>
                {form.getFieldValue("image") && (
                  <Image
                    height={48}
                    width={48}
                    src={form.getFieldValue("image")}
                    preview={{ mask: <EyeOutlined /> }}
                  />
                )}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default AppBannerForm;
