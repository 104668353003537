import React from "react";
import {
  Input,
  Row,
  Col,
  Form,
  Button,
  Select,
  Upload,
  Divider,
  message,
  Card,
  Space,
  Avatar,
  Image,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { bloodGroups, calculateAge } from "../../utils/date";
import { getBase64 } from "../../utils/file";

const { Option } = Select;

const FamilyMemberForm = ({ form, initialValues }) => {
  const onDobChange = (dateOfBirth, index) => {
    const age = calculateAge(dateOfBirth);
    form.setFieldsValue({
      members: form
        .getFieldValue("members")
        .map((member, idx) =>
          idx === index ? { ...member, dateOfBirth, age } : member
        ),
    });
  };

  const handleUploadRequest = ({ file, onSuccess, onError }) => {
    onSuccess();
  };
  return (
    <Card title="Family Members">
      <p>Add Family Members with their basic details</p>
      <div className="mt-3">
        <Form.List name="members">
          {(fields, { add, remove }) => (
            <div className="mt-3">
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <div key={key}>
                  <h4 className="my-4 secondary-color">
                    Family Member {index + 1}
                  </h4>
                  <Row gutter={16}>
                    <Col sm={24} md={7}>
                      <Form.Item
                        {...restField}
                        name={[name, "memberRelationId"]}
                        fieldKey={[fieldKey, "memberRelationId"]}
                        label="Relation"
                        rules={[
                          {
                            required: true,
                            message: "Please select a relation",
                          },
                        ]}
                      >
                        <Select
                          className="w-100"
                          style={{ marginBottom: "10px" }}
                        >
                          <Option value="2">Spouse</Option>
                          <Option value="3">Son</Option>
                          <Option value="4">Daughter</Option>
                          <Option value="5">Father</Option>
                          <Option value="6">Mother</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "firstName"]}
                        fieldKey={[fieldKey, "firstName"]}
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the name",
                          },
                        ]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "middelName"]}
                        fieldKey={[fieldKey, "middelName"]}
                        label="Middle Name"
                      >
                        <Input placeholder="Middle Name" />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "lastName"]}
                        fieldKey={[fieldKey, "lastName"]}
                        label="Surname"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the surname",
                          },
                        ]}
                      >
                        <Input placeholder="Surname" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "officeTelNo"]}
                        fieldKey={[fieldKey, "officeTelNo"]}
                        label="Telephone No. (O)"
                      >
                        <Input placeholder="Telephone No. (O)" />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "residenceTelNo"]}
                        fieldKey={[fieldKey, "residenceTelNo"]}
                        label="Residence"
                      >
                        <Input placeholder="Residence" />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "mobileNumber"]}
                        fieldKey={[fieldKey, "mobileNumber"]}
                        label="Mobile"
                      >
                        <Input placeholder="Mobile" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "email"]}
                        fieldKey={[fieldKey, "email"]}
                        label="Email ID"
                      >
                        <Input type="email" placeholder="Email ID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "dateOfBirth"]}
                        fieldKey={[fieldKey, "dateOfBirth"]}
                        label="Date Of Birth"
                        rules={[
                          {
                            validator: (_, value) =>
                              value && value != "0000-00-00"
                                ? new Date(value) < new Date()
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        "Date of birth must be in the past!"
                                      )
                                    )
                                : Promise.resolve(),
                          },
                        ]}
                      >
                        <Input
                          type="date"
                          placeholder="Date Of Birth"
                          onChange={(e) => onDobChange(e.target.value, index)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "age"]}
                        fieldKey={[fieldKey, "age"]}
                        label="Age"
                      >
                        <Input placeholder="Age" readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "gender"]}
                        fieldKey={[fieldKey, "gender"]}
                        label="Gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select a gender",
                          },
                        ]}
                      >
                        <Select
                          className="w-100"
                          style={{ marginBottom: "10px" }}
                        >
                          <Option value="0">Male</Option>
                          <Option value="1">Female</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "bloodGroup"]}
                        fieldKey={[fieldKey, "bloodGroup"]}
                        label="Blood Group"
                      >
                        <Select placeholder="" autocomplete="none">
                          {bloodGroups.map((group) => (
                            <Option key={group} value={group}>
                              {group}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "panNo"]}
                        fieldKey={[fieldKey, "panNo"]}
                        label="Pan No."
                      >
                        <Input placeholder="Pan No." />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "internalCardNumber"]}
                        fieldKey={[fieldKey, "internalCardNumber"]}
                        label="Internal Card Number"
                      >
                        <Input placeholder="Internal Card Number" />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "externalCardNo"]}
                        fieldKey={[fieldKey, "externalCardNo"]}
                        label="External Card Number"
                      >
                        <Input placeholder="External Card Number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8} className="px-1">
                      <Form.Item
                        {...restField}
                        name={[name, "photo"]}
                        fieldKey={[fieldKey, "photo"]}
                        label="Passport Size Photo"
                        valuePropName="fileList"
                      >
                        <Space>
                          <Upload
                            name="photo"
                            listType="picture"
                            beforeUpload={(file) => {
                              const isValidFileType = [
                                "image/jpeg",
                                "image/jpg",
                                "image/png",
                                "image/webp",
                              ].includes(file.type);
                              if (!isValidFileType) {
                                message.error(
                                  "You can only upload JPG, JPEG, PNG, or WEBP files!"
                                );
                              }
                              return true;
                            }}
                            maxCount={1}
                            accept=".jpg,.jpeg,.png,.webp"
                            customRequest={handleUploadRequest}
                            onChange={async ({ fileList }) => {
                              if (fileList.length > 0) {
                                const file = fileList[fileList.length - 1];
                                const base64 = await getBase64(
                                  file.originFileObj
                                );
                                const updatedMembers = form
                                  .getFieldValue("members")
                                  .map((member, idx) =>
                                    idx === index
                                      ? { ...member, photo: base64 }
                                      : member
                                  );
                                form.setFieldsValue({
                                  members: updatedMembers,
                                });
                              }
                            }}
                            onRemove={() => {
                              const updatedMembers = form
                                .getFieldValue("members")
                                .map((member, idx) =>
                                  idx === index
                                    ? { ...member, photo: null }
                                    : member
                                );
                              form.setFieldsValue({
                                members: updatedMembers,
                              });
                            }}
                          >
                            <Button>
                              <UploadOutlined /> Click to upload
                            </Button>
                          </Upload>
                          {form.getFieldValue(["members", index, "photo"]) && (
                            <Image
                              src={form.getFieldValue([
                                "members",
                                index,
                                "photo",
                              ])}
                              height={48}
                              width={48}
                              preview={{ mask: <EyeOutlined /> }}
                            />
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col span={8} className="px-1"></Col>
                    <Col span={8}>
                      <Tooltip title="Remove">
                        <Button
                          className="mt-md-4 float-right"
                          style={{ color: "red" }}
                          icon={<DeleteOutlined />}
                          onClick={() => remove(name)}
                          size="small"
                        />
                      </Tooltip>
                    </Col>
                  </Row>

                  <Divider />
                </div>
              ))}
              <Button type="dashed" onClick={() => add()} className="w-100">
                <PlusOutlined />
              </Button>
            </div>
          )}
        </Form.List>
      </div>
    </Card>
  );
};

export default FamilyMemberForm;
