/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../Layouts/Layout";
import {
  Button,
  Card,
  Image,
  Input,
  message,
  Popconfirm,
  Skeleton,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import Flex from "../../Components/Ui/Flex";
import EventView from "../../Components/event/EventView";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "./../../store/api/fetchEvents/index";
import { eventMap } from "../../utils/date";
import { deleteEvent } from "../../store/api/DeleteEvent";

export default function Events() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventVisible, setEventVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(location.state?.page || 1);
  const pageSize = 100;

  const { data, total, isLoading } = useSelector(
    (state) => state.eventsReducer
  );

  const [sortField, setSortField] = useState(location.state?.sortField || "");
  const [sortOrder, setSortOrder] = useState(location.state?.sortOrder || "");
  const [searchTerm, setSearchTerm] = useState(
    location.state?.searchTerm || ""
  );

  const fetchData = () => {
    dispatch(
      fetchEvents({
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        sortBy: sortField || "startDate",
        orderTo: sortOrder || "DESC",
        ...(searchTerm && { searchText: searchTerm }),
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, sortOrder, sortField, searchTerm]);

  const handleTableChange = (pagination, _filter, sorter) => {
    setCurrentPage(pagination.current);
    if (sorter && sorter.field && sorter.order) {
      setSortField(sorter.field);
      setSortOrder(sorter.order === "ascend" ? "ASC" : "DESC");
    } else {
      setSortField("");
      setSortOrder("");
    }
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      setSearchTerm(value);
    }, 300),
    []
  );

  const handleSearch = (e) => {
    debounceSearch(e.target.value);
  };

  const editEventRoute = (id) => {
    navigate(`/events/edit-events/${id}`, {
      state: {
        page: currentPage,
        sortField,
        sortOrder,
        searchTerm,
      },
    });
  };

  const addEventRoute = () => {
    navigate(`/events/add-events`);
  };

  const visitedMemberRoute = (id) => {
    navigate(`/events/visited-member/${id}`);
  };

  const showEvent = (event) => {
    setEventVisible(true);
    setSelectedEvent(event);
  };

  const closeEvent = () => {
    setEventVisible(false);
    setSelectedEvent(null);
  };

  function confirm(e) {
    dispatch(deleteEvent(e))
      .then(() => {
        message.success("Member deleted successfully");
        fetchData();
      })
      .catch((error) => {
        message.error("Failed to delete member: " + error.message);
      });
  }

  function cancel(e) {
    console.log(e);
    message.error("Click on No");
  }

  const tableColumns = useMemo(
    () => [
      {
        title: "Event Name",
        dataIndex: "name",
        render: (_, record) => (
          <div className="d-flex align-items-center">
            <Image
              height={48}
              width={48}
              src={record.featuredImage}
              style={{ borderRadius: "10px" }}
            />
            <span className="ml-2">{record.name}</span>
          </div>
        ),
        sorter: true,
      },
      {
        title: "Event Start Date",
        dataIndex: "startDate",
        render: (_, record) => record.startDate.split(" ")[0],
        sorter: true,
      },
      {
        title: "Event End Date",
        dataIndex: "endDate",
        render: (_, record) => record.endDate.split(" ")[0],
        sorter: true,
      },
      {
        title: "Event Status",
        dataIndex: "status",
        render: (_, record) => {
          let color;
          switch (record.status) {
            case "1":
              color = "blue";
              break;
            case "2":
              color = "orange";
              break;
            case "3":
              color = "green";
              break;
            default:
              color = "default";
          }
          return <Tag color={color}>{eventMap[Number(record.status)]}</Tag>;
        },
        sorter: true,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (_, elm) => (
          <div className="text-right d-flex justify-content-start">
            <Tooltip title="View">
              <Button
                type="info"
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => showEvent(elm)}
                size="small"
              />
            </Tooltip>
            <Tooltip title="Update">
              <Button
                type="info"
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => editEventRoute(elm.id)}
                size="small"
              />
            </Tooltip>
            <Popconfirm
              placement="bottomLeft"
              title={`Are you sure to delete ${elm.name}?`}
              onConfirm={() => confirm(elm.id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button
                  style={{ color: "red" }}
                  icon={<DeleteOutlined />}
                  size="small"
                />
              </Tooltip>
            </Popconfirm>
            <Tooltip title="Visited Members">
              <Button
                type="info"
                className="ml-2"
                icon={<UsergroupAddOutlined />}
                onClick={() => visitedMemberRoute(elm.id)}
                size="small"
                disabled={elm.status === "1"}
              />
            </Tooltip>
          </div>
        ),
      },
    ],
    [eventMap, showEvent, editEventRoute, confirm, cancel, visitedMemberRoute]
  );

  const skeletonColumns = useMemo(
    () =>
      tableColumns.map((col) => ({
        ...col,
        render: () => (
          <Skeleton.Button style={{ width: "150px", height: 32 }} active />
        ),
      })),
    [tableColumns]
  );

  const skeletonData = useMemo(
    () =>
      Array.from({ length: 5 }, (_, index) => ({ key: `skeleton-${index}` })),
    []
  );

  return (
    <Layout>
      <Card>
        <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
          <Flex className="mb-1" mobileFlex={false}>
            <div className="mr-md-3 mb-3">
              <Input
                defaultValue={searchTerm}
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={handleSearch}
                allowClear
              />
            </div>
            <h5 className="d-flex align-items-center">
              <Tag color={(total || 0) < 1 ? "red" : "green"}>
                {`Total Results Found: `} {`${total || 0}`}
              </Tag>
            </h5>
          </Flex>
          <div className="addButton">
            <Button
              onClick={addEventRoute}
              type="primary"
              icon={<PlusCircleOutlined />}
              block
            >
              Add Event
            </Button>
          </div>
        </Flex>
        <div className="table-responsive">
          <Table
            columns={isLoading ? skeletonColumns : tableColumns}
            dataSource={isLoading ? skeletonData : data}
            rowKey="id"
            pagination={{
              current: currentPage,
              pageSize,
              total: total,
            }}
            onChange={handleTableChange}
          />
        </div>
        {selectedEvent && (
          <EventView
            data={selectedEvent}
            visible={eventVisible}
            loading={true}
            close={() => closeEvent()}
          />
        )}
      </Card>
    </Layout>
  );
}
