import React from "react";

import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Upload,
  Button,
  Switch,
  Select,
  Space,
  Image,
} from "antd";
import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { bloodGroups } from "../../utils/date";

const { Option } = Select;

export default function GeneralForm(props) {
  const handleUploadRequest = ({ file, onSuccess, onError }) => {
    onSuccess();
  };

  return (
    <Row>
      <Col xs={24}>
        <Card title="Basic Info">
          <Row>
            <Col span={8} key={1} className="px-1">
              <Form.Item
                label="Name"
                name="firstName"
                rules={[{ required: true, message: "Please enter the name" }]}
              >
                <Input placeholder="Name" autocomplete="none"/>
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item
                label="Father / Husband's Name"
                name="middelName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Father / Husband's Name",
                  },
                ]}
              >
                <Input placeholder="Father / Husband's Name*" autocomplete="none"/>
              </Form.Item>
            </Col>
            <Col span={8} key={3} className="px-1">
              <Form.Item
                label="Surname"
                name="lastName"
                rules={[
                  { required: true, message: "Please enter the surname" },
                ]}
              >
                <Input placeholder="Surname" autocomplete="none"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} key={1} className="px-1">
              <Form.Item
                label="Postal Address"
                name="postalAddress"
                rules={[
                  {
                    required: true,
                    message: "Please enter the postal address",
                  },
                ]}
              >
                <Input.TextArea rows={4} autocomplete="none"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} key={3} className="px-1">
              <Form.Item label="Pin Code" name="pinCode"
              rules={[
                {
                  pattern: /^[0-9]{6}$/,
                  message: "Pin code must be 6 digits long!",
                },
              ]}
              >
                <Input placeholder="Pin Code" autocomplete="none"/>
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item label="City" name="city">
                <Input placeholder="City" autocomplete="none"/>
              </Form.Item>
            </Col>
            <Col span={8} key={1} className="px-1">
              <Form.Item label="State" name="state">
                <Input placeholder="State" autocomplete="none"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} key={1} className="px-1">
              <Form.Item
                name="sameAsPostalAddress"
                label="Same as Postal Address"
              >
                <Switch checked={props.sameAsPostal} onChange={props.switchOnChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} key={1} className="px-1">
              <Form.Item
                label="Permanent Address"
                name="permanentAddress"
                rules={[
                  {
                    required: true,
                    message: "Please enter the permanent address",
                  },
                ]}
              >
                <Input.TextArea rows={4} readOnly={props.sameAsPostal} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} key={3} className="px-1">
              <Form.Item label="Pin Code" name="permanentAddressPinCode"
              rules={[
                {
                  pattern: /^[0-9]{6}$/,
                  message: "Pin code must be 6 digits long!",
                },
              ]}
              >
                <Input
                  type="text"
                  placeholder="Pin Code"
                  readOnly={props.sameAsPostal}
                />
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item label="City" name="permanentAddressCity">
                <Input
                  type="text"
                  placeholder="City"
                  readOnly={props.sameAsPostal}
                />
              </Form.Item>
            </Col>
            <Col span={8} key={1} className="px-1">
              <Form.Item name="permanentAddressState" label="State">
                <Input
                  type="text"
                  placeholder="State"
                  readOnly={props.sameAsPostal}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} key={1} className="px-1">
              <Form.Item label="Telephone No. (O)" name="officeTelNo">
                <Input type="tel" placeholder="Telephone No. (O)" />
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item label="Residence Tel. No" name="residenceTelNo">
                <Input type="tel" placeholder="Residence Tel. No" />
              </Form.Item>
            </Col>
            <Col span={8} key={3} className="px-1">
              <Form.Item
                label="Mobile"
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your mobile number!",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Mobile number must be 10 digits long!",
                  },
                ]}
              >
                <Input type="tel" placeholder="Mobile*" autocomplete="none"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} key={1} className="px-1">
              <Form.Item label="Member Email" name="email">
                <Input type="email" placeholder="Member Email" />
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item label="Pan No." name="panNo">
                <Input type="text" placeholder="Pan No." />
              </Form.Item>
            </Col>
            <Col span={8} key={3} className="px-1">
              <Form.Item label="Adhaar Card No." name="adhaarNo">
                <Input type="text" placeholder="Adhaar Card No." />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} key={1} className="px-1">
              <Form.Item
                label="Date Of Birth"
                name="dateOfBirth"
                rules={[
                  {
                    required: true,
                    message: "Please select your date of birth!",
                  },
                  {
                    validator: (_, value) =>
                      value && new Date(value) < new Date()
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Date of birth must be in the past!")
                          ),
                  },
                ]}
              >
                <Input type="date" placeholder="" />
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item label="Age" name="age" required>
                <Input type="text" placeholder="Age" readOnly />
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Please select gender!",
                  },
                ]}
              >
                <Select className="w-100 mb-2">
                  <Option value="0">Male</Option>
                  <Option value="1">Female</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} key={1} className="px-1">
              <Form.Item label="Marital Status" name="maritalStatus">
                <Select className="w-100" style={{ marginBottom: "10px" }}>
                  <Option value="0">Married</Option>
                  <Option value="1">Unmarried</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item label="Date Of Marriage" name="dateOfMarriage"
              rules={[
                {
                  validator: (_, value) =>
                  value && value != "0000-00-00"
                      ? new Date(value) < new Date()
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "Date of birth must be in the past!"
                            )
                          )
                      : Promise.resolve(),
                },
              ]}>
                <Input type="date" placeholder="Date Of Marriage" />
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item label="Blood Group" name="bloodGroup">
                <Select placeholder="Select Blood Group"autocomplete="none">
                  {bloodGroups.map((group) => (
                    <Option key={group} value={group}>
                      {group}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} key={1} className="px-1">
              <Form.Item label="Qualification" name="qualification">
                <Input type="text" placeholder="Qualification" />
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item label="Occupation / Profession" name="occupation">
                <Input type="text" placeholder="Occupation / Profession" />
              </Form.Item>
            </Col>
            <Col span={8} key={2} className="px-1">
              <Form.Item label="Name Of Company / Firm" name="nameOfCompany">
                <Input type="text" placeholder="Name Of Company / Firm" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} key={1} className="px-1">
              <Form.Item name="internalCardNumber" label="Internal Card Number">
                <Input type="text" placeholder="Internal Card Number" autocomplete="none"/>
              </Form.Item>
            </Col>
            <Col span={12} key={1} className="px-1">
              <Form.Item name="externalCardNumber" label="External Card Number">
                <Input type="text" placeholder="External Card Number" autocomplete="none"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} key={1} className="px-1">
              <Form.Item
                name="photo"
                label="Passport Size Photo"
                valuePropName="fileList"
              >
                <Space>
                  <Upload
                    name="photo"
                    listType="picture"
                    beforeUpload={props.beforeUpload}
                    maxCount={1}
                    accept=".jpg,.jpeg,.png,.webp"
                    customRequest={handleUploadRequest}
                    onChange={props.handleChange}
                    onRemove={props.handleRemove}
                  >
                    <Button>
                      <UploadOutlined />
                      Click to upload
                    </Button>
                  </Upload>
                  {props.form.getFieldValue("photo") && (
                    <Image
                      height={48}
                      width={48}
                      src={props.form.getFieldValue("photo")}
                      preview={{ mask: <EyeOutlined /> }}
                    />
                  )}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Col>
      {/* <Col xs={24} sm={24} md={7}>
        <Card title="Organization">
          <Form.Item label="Type">
            <Select className="w-100" placeholder="Type">
              <Option value="Option 1">Option 1</Option>
              <Option value="Option 2">Option 2</Option>
              <Option value="Option 3">Option 3</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Tags">
            <Select mode="tags" style={{ width: "100%" }} placeholder="Tags">
              <Option value="Tag 1">Tag 1</Option>
              <Option value="Tag 2">Tag 2</Option>
              <Option value="Tag 3">Tag 3</Option>
            </Select>
          </Form.Item>
        </Card>
      </Col> */}
    </Row>
  );
}
