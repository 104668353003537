import React, { useEffect } from "react";
import Layout from "../../Layouts/Layout";
import { Button, Card, Form, message, Spin, Upload } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Flex from "../../Components/Ui/Flex";
import { getBase64 } from "../../utils/file";
import StaffForm from "../../Components/staff/StaffForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaff } from "../../store/api/fetchStaff";
import { updateStaff } from "../../store/api/UpdateStaff";
import { clearStaff } from "../../store/feature/staff/staffSlice";
import { getUserData, storeUserData } from "../../utils/token";

const EditStaff = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getUserData();
  const { data: staff, isLoading } = useSelector((state) => state.staffReducer);
  const { isUpdatingLoading } = useSelector(
    (state) => state.updateStaffReducer
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchStaff({ id }));
    }
  }, [id, form, dispatch]);

  const onDiscard = () => {
    dispatch(clearStaff());
    navigate("/staff");
  };

  const onFinish = (values) => {
    const payload = { id, ...values, email: values.email?.toLowerCase() };

    if (
      values.profilePicture === staff.profilePicture ||
      !payload.profilePicture
    ) {
      delete payload.profilePicture;
    }
    dispatch(updateStaff(payload))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("Staff successfully updated!");
          if (user.id === id) {
            storeUserData(response.Data);
          }
          dispatch(clearStaff());
          navigate("/staff");
        } else {
          message.error(response.Message);
        }
      })
      .catch((error) => {
        message.error("Failed to update member. Please try again.");
        console.error("update member error:", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const beforeUpload = (file) => {
    const isValidFileType = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/jpg",
    ].includes(file.type);

    if (!isValidFileType) {
      message.error("You can only upload JPG, PNG, or WEBP files!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = async ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[fileList.length - 1];
      const base64 = await getBase64(file.originFileObj);

      form.setFieldsValue({
        profilePicture: base64,
      });
    }
  };

  const handleRemove = () => {
    form.setFieldsValue({
      profilePicture: staff?.profilePicture || null,
    });
  };

  const onFormValuesChange = (changedValues) => {
    if (
      changedValues.profilePicture &&
      typeof changedValues.profilePicture !== "string"
    ) {
      handleRemove();
    }
  };

  return (
    <>
      <Layout>
        <Card>
          {isLoading || !staff ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <Form
              form={form}
              layout="vertical"
              name="advanced_search"
              className="ant-advanced-search-form"
              initialValues={staff}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onFormValuesChange}
            >
              <div className="container">
                <Flex
                  className="py-2"
                  mobileFlex={false}
                  justifyContent="between"
                  alignItems="center"
                >
                  <h2 className="mb-3">Update Staff</h2>
                  <div className="mb-3">
                    <Button className="mr-2" onClick={() => onDiscard()}>
                      Discard
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isUpdatingLoading}
                    >
                      Update
                    </Button>
                  </div>
                </Flex>
              </div>
              <div className="container mt-0">
                <StaffForm
                  form={form}
                  staff={staff}
                  beforeUpload={beforeUpload}
                  handleChange={handleChange}
                  handleRemove={handleRemove}
                />
              </div>
            </Form>
          )}
        </Card>
      </Layout>
    </>
  );
};

export default EditStaff;
