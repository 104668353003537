import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const updateStaff = createAsyncThunk(
  "staffs/updateStaff",
  async (staffData, thunkAPI) => {
    try {
      const response = await instance.put(
        `/backyardUser/${staffData.id}`,
        staffData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to update staff"
      );
    }
  }
);
