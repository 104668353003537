import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { Row, Col, Card, Typography, Layout, Divider, Skeleton, Button } from "antd";
import CustomAvatar from "../../Components/common/Avatar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMembershipToken } from "../../store/api/fetchMembershipToken";
import { roleMap } from "../../utils/date";
// import html2canvas from 'html2canvas';
import { toPng, toJpeg } from 'html-to-image';
import { domToPng } from 'modern-screenshot'
import { jsPDF } from 'jspdf';
import { CloudDownloadOutlined, DownloadOutlined } from "@ant-design/icons";


const { Title, Text } = Typography;
const { Content } = Layout;

export default function QrCodeDetails() {
  const { id } = useParams();
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(
    (state) => state.membershipTokenReducer
  );
  const elementRef = useRef(null);

  useEffect(() => {
    if (id) {
      dispatch(fetchMembershipToken({ id }));
    }
  }, [id]);

  const isMobile = window.innerWidth < 768;

  const download = ()=>{
    setIsDownloading(true); 

    domToPng(elementRef.current, { useCors: true,
        cacheBust: false
      })
    .then((dataUrl) => {
      // const link = document.createElement("a");
      // link.download = "E-pass.png";
      // link.href = dataUrl;
      // link.click();
      const pdf = new jsPDF(); // Initialize jsPDF

        // Get the image properties (width and height)
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        
        // Calculate the height to maintain aspect ratio
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        // If the image height is greater than the default page height, adjust the PDF page size
        if (pdfHeight > pdf.internal.pageSize.getHeight()) {
          pdf.internal.pageSize.height = pdfHeight;
        }

        // Add the image to the PDF
        pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);

          // Create Blob and open in a new tab
          const pdfBlob = pdf.output('blob');
          // const pdfUrl = URL.createObjectURL(pdfBlob);
  
          const url = window.URL.createObjectURL(new Blob([pdfBlob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Oliv-QR-Code.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
          

      setIsDownloading(false); 
    })
    .catch((err) => {
      console.log(err);
      setIsDownloading(false); 
    });
  }

  return (
    <Content ref={elementRef} style={{ padding: "20px", maxWidth: "800px", margin: "0 auto", background: "#fff" }}>
      
      <div className="text-center">
        <img
          className="img-fluid pb-3"
          width={100}
          src={`/img/${"logo-dark.png"}`}
          alt=""
        />
      </div>
      <Title level={3} style={{ textAlign: "center", color: "#4a4a4a" }}>
        Oliv Club - Suvarna Navratri 2024
      </Title>
      <Divider></Divider>
      <Title
        level={2}
        style={{
          textAlign: "center",
          color: "#4a4a4a",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        Membership:{" "}
        {isLoading ? (
          <Skeleton.Input style={{ marginTop: 4 }} size="small" active />
        ) : (
          data?.membershipCode
        )}
      </Title>
      <Divider>Your QR Code Details</Divider>
      {/* <Title level={4} style={{ textAlign: "center", color: "#4a4a4a" }}>
        Your QR Code Details
      </Title> */}
      
      {!isDownloading && (
        <Title level={4} style={{ textAlign: "center", color: "#4a4a4a" }}>
          <Button icon={<DownloadOutlined />} className="ant-btn-primary text-center" onClick={()=>download()}> <span className="ml-2">Download</span></Button>
       </Title>
      )}
      <div>
      {isLoading ? (
        <Row gutter={[16, 32]} justify="center">
          {Array.from({ length: 2 }).map((_, index) => (
            <Col key={index} xs={24} sm={12}>
              <Card
                bordered={true}
                style={{
                  textAlign: "center",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Skeleton active paragraph={{ rows: 4 }} />
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        data?.members.map((member, index) => {
          const qrValue = {
            MemberCode: data.membershipCode,
            MemberName: `${member.firstName} ${member.lastName}`,
            MemberId: member.id,
            MemberStatus: member.isActive === "1" ? "Active" : "Inactive",
          };

          return (
            <Card
              key={`${index}-${member.id}`}
              bordered={true}
              style={{
                textAlign: "center",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Row gutter={[16, 32]} align="middle">
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <QRCode
                    size={180}
                    value={JSON.stringify(qrValue)}
                    style={{
                      height: "auto",
                      width: "180px",
                      border: "1px solid #e1e1e1",
                      borderRadius: "8px",
                      padding: "10px",
                      backgroundColor: "#f9f9f9",
                    }}
                  />
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ textAlign: isMobile ? "center" : "left" }}
                >
                  <Row
                    className="d-flex flex-column"
                    align="middle"
                    justify={isMobile ? "center" : "start"}
                    style={{ marginBottom: 16, textAlign: "center" }}
                  >
                   
                   <CustomAvatar
                      name={member.firstName}
                      surname={member.lastName}
                      profile={member.photo}
                      size={120}
                      style={{
                        marginRight: isMobile ? 0 : 16,
                        border: "2px solid #f56a00",
                        color: "#ffffff",
                        backgroundColor: "#f56a00",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "40px",
                      }}
                    />
                    <Title
                      className="mt-2"
                      level={4}
                      style={{ marginBottom: 0, textTransform: "capitalize" }}
                    >
                      {qrValue.MemberName}
                    </Title>
                  </Row>
                  <Divider style={{ margin: "10px 0" }} />
                  <Text strong style={{ color: "#333", textAlign: "center" }}>
                    Member Relationship:{" "}
                    <Text>{roleMap[member.memberRelationId] || "Unknown"}</Text>
                  </Text>
                </Col>
              </Row>
            </Card>
          );
        })
      )}
      <Divider/>
        <div>
          <h3>Important Note:</h3>
            <p>1. The gates will open at 8:00 PM and close at 10:30 PM.</p>
            <p>2. Oliv Club members are requested to <b> enter through the Oliv Club Lounge area.</b></p>
            <p>3. This QR code is only valid for <b>5th to 14th Oct 2024</b>.</p>
            <p>4. Please ensure your <b>QR Code</b> is ready for quick entry.</p>
            <p>5. <b>Download your QR Code</b> in advance before arriving at the premises.</p>
            <p>6. Traditional Garba attire is mandatory to participate on the dance floor.</p>
            <p>7. Guests of members are not allowed.</p>
            <p>8. Entry will be on a first-come, first-served basis, and no one will be permitted after that.</p>
            <p>9. You can also enter using your membership card.</p>
            <p>10. All members must have cleared their dues.</p>
            <p><b>Oliv Club - 9099912047</b></p>
        </div>
      </div>
    </Content>
  );
}
