import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("apiKey");
  if (token) {
    config.headers["ApiKey"] = token;
  }
  return config;
});

export default instance
