import { createSlice } from "@reduxjs/toolkit";
import { verifyOTP } from "../../api/verifyOTP";

const initialState = {
  data: null,
  isVerifyingLoading: false,
};

export const verifyOTPSlice = createSlice({
  name: "verifyOTP",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyOTP.pending, (state) => {
        state.isVerifyingLoading = true;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.isVerifyingLoading = false;
        state.data = action.payload;
      })
      .addCase(verifyOTP.rejected, (state) => {
        state.isVerifyingLoading = false;
        state.data = null;
      });
  },
});

export default verifyOTPSlice.reducer;
