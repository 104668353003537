import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const fetchEvent = createAsyncThunk(
  "events/fetchEvent",
  async ({ id }, thunkAPI) => {
    try {
      const response = await instance.get(`/event/${id}`);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to fetch event"
      );
    }
  }
);
