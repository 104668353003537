import { Col, Drawer, Tag, Row, Button, Image } from "antd";
import React from "react";
import { EyeOutlined } from "@ant-design/icons";
import { eventMap } from "../../utils/date";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const EventView = (props) => {
  const { data, visible, close } = props;

  function convertDate(dateString) {
    const inputDate = new Date(dateString);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = inputDate.getDate();
    const month = inputDate.getMonth();
    const year = inputDate.getFullYear();

    return `${months[month]} ${day}, ${year}`;
  }
  let color;
  switch (data.status) {
    case "1":
      color = "blue";
      break;
    case "2":
      color = "orange";
      break;
    case "3":
      color = "green";
      break;
    default:
      color = "default";
  }
  return (
    <Drawer
      width={380}
      motion={null}
      placement="right"
      closable={false}
      onClose={close}
      open={visible}
    >
      <div style={{ minHeight: "220px" }}>
        <Image width="100%" height="auto" src={data.featuredImage} />
      </div>
      <p className="site-description-item-profile-p mt-3">
        Event Details
        <Tag className="ml-2" color={color}>
          {eventMap[Number(data.status)]}
        </Tag>
      </p>
      <Row className="pb-3">
        <Col span={24}>
          <DescriptionItem title="Event Name" content={`${data.name}`} />
        </Col>
      </Row>
      <Row className="pb-3">
        <Col span={12}>
          <DescriptionItem
            title="Event Start Date"
            content={data?.startDate ? convertDate(data.startDate) : "-"}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Event End Date"
            content={data?.endDate ? convertDate(data.endDate) : "-"}
          />
        </Col>
      </Row>
      <Row className="pb-3">
        {data?.registrationLink && (
          <Col span={14}>
            <a
              href={data.registrationLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="link" style={{ color: "orange" }}>
                <EyeOutlined />
                Registration Link
              </Button>
            </a>
          </Col>
        )}
        {data?.socialMediaLink && (
          <Col span={10}>
            <a
              href={data.socialMediaLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="link" style={{ color: "orange" }}>
                <EyeOutlined /> Social Link
              </Button>
            </a>
          </Col>
        )}
      </Row>
      {data.bannerImage && (
        <div style={{ minHeight: "220px" }}>
          <DescriptionItem title="Banner Image" />
          <Image width="100%" height="auto" src={data.bannerImage} />
        </div>
      )}
    </Drawer>
  );
};

export default EventView;
