import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const fetchVisitedMembers = createAsyncThunk(
  "events/fetchVisitedMembers",
  async ({ event_id, ...params }, thunkAPI) => {
    try {
      const response = await instance.get(`/event/${event_id}/attend-member`, {
        params,
      });
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to fetch visited members"
      );
    }
  }
);

export const fetchExportVisitedMembers = createAsyncThunk(
  "events/exportVisitedMembers",
  async ({ event_id, ...params }, thunkAPI) => {
    try {
      const response = await instance.get(`/event/${event_id}/attend-member`, {
        params,
      });
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to fetch visited members"
      );
    }
  }
);
