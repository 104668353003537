import { createSlice } from "@reduxjs/toolkit";
import { fetchMemberById } from "../../api/fetchMember";

const initialState = {
  data: null,
  isLoading: false,
  error: "",
};

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    resetMember: (state) => {
      state.data = null;
      state.isLoading = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMemberById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMemberById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload;
      })
      .addCase(fetchMemberById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch member data";
      });
  },
});

export const { resetMember } = memberSlice.actions;

export default memberSlice.reducer;
