import { createSlice } from "@reduxjs/toolkit";
import { addStaff } from "../../api/AddStaff";

const initialState = {
  data: null,
  isAddingLoading: false,
};

export const addStaffSlice = createSlice({
  name: "addStaff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addStaff.pending, (state) => {
        state.isAddingLoading = true;
      })
      .addCase(addStaff.fulfilled, (state, action) => {
        state.isAddingLoading = false;
        state.data = action.payload;
      })
      .addCase(addStaff.rejected, (state) => {
        state.isAddingLoading = false;
        state.data = null;
      });
  },
});

export default addStaffSlice.reducer;
