import { createSlice } from "@reduxjs/toolkit";
import { fetchStaffs } from "../../api/fetchStaffs";

const initialState = {
  data: [],
  page: 1,
  limit: 5,
  total: 0,
  isLoading: false,
  error: "",
};
export const staffsSlice = createSlice({
  name: "staffs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStaffs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStaffs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload.data;
        state.page = action.payload.page;
        state.total = action.payload.total;
      })
      .addCase(fetchStaffs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch data";
      });
  },
});

export default staffsSlice.reducer;
