import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const updateMember = createAsyncThunk(
  "members/updateMember",
  async (memberData, thunkAPI) => {
    try {
      const response = await instance.put(
        `/membership/${memberData.id}`,
        memberData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to update member"
      );
    }
  }
);
