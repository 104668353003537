import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from './../../../services/axiosService';

export const fetchSignUp = createAsyncThunk(
  "auth/fetchAuth",
  async (body, thunkAPI) => {
    try {
      const response = await instance.post("/backyardUser/signin", body);
      return response.data.Data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.Message || "Login Failed"
      );
    }
  }
);
