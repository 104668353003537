import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const exportMembers = createAsyncThunk(
  "members/exportMembers",
  async (_, thunkAPI) => {
    try {
      const response = await instance.get("/member/export");

      return {
        data: response.data.Data,
        total: response.data.Total,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to fetch export data"
      );
    }
  }
);
