export const storeUserData = (user) => {
  const userData = JSON.stringify(user);
  localStorage.setItem("userData", userData);
  localStorage.setItem("apiKey", user.apiKey);
};

export const getUserData = () => {
  const userData = localStorage.getItem("userData");
  return userData ? JSON.parse(userData) : null;
};

export const removeUserData = () => {
  localStorage.clear();
};

