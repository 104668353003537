import { Col, Drawer, Row, Button, Tooltip } from "antd";
import React from "react";
import CustomAvatar from "../common/Avatar";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const StaffView = (props) => {
  const { data, visible, close } = props;
  return (
    <Drawer
      width={340}
      motion={null}
      placement="right"
      closable={false}
      onClose={close}
      open={visible}
    >
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <CustomAvatar
          name={data?.firstName}
          surname={data?.lastName}
          profile={data.profilePicture}
          size={128}
          style={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            marginRight: "0px",
            fontSize: "40px",
            display: "inline-flex",
          }}
        />
      </div>
      <p className="site-description-item-profile-p mt-3">
        Staff Details
        <Tooltip title={data.isActive ? "Active" : "Inactive"}>
          <Button
            type="link"
            style={{
              color: data.isActive ? "#87d068" : "#f50",
            }}
          >
            {data.isActive ? "Active" : "Inactive"}
          </Button>
        </Tooltip>
      </p>
      <Row className="pb-3">
        <Col span={12}>
          <DescriptionItem
            title="Name"
            content={`${data.firstName} ${data.lastName}`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Email" content={data.email} />
        </Col>
      </Row>
      <Row className="pb-3">
        <Col span={12}>
          <DescriptionItem title="Phone Number" content={data.phoneNumber} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Role" content={data.backyardUserRole} />
        </Col>
      </Row>

      <Row className="pb-3">
        <Col span={24}>
          <DescriptionItem title="Address" content={data?.address || "-"} />
        </Col>
      </Row>
    </Drawer>
  );
};

export default StaffView;
