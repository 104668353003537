import { createSlice } from "@reduxjs/toolkit";
import { addGalleryImage } from "../../api/AddGalleryImage";

const initialState = {
  data: null,
  isAddingLoading: false,
};

export const addGalleryImageSlice = createSlice({
  name: "addGalleryImage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addGalleryImage.pending, (state) => {
        state.isAddingLoading = true;
      })
      .addCase(addGalleryImage.fulfilled, (state, action) => {
        state.isAddingLoading = false;
        state.data = action.payload;
      })
      .addCase(addGalleryImage.rejected, (state) => {
        state.isAddingLoading = false;
        state.data = null;
      });
  },
});

export default addGalleryImageSlice.reducer;
