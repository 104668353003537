import {
  Col,
  Drawer,
  Row,
  Skeleton,
  Tag,
  Image,
  Empty,
  Card,
  Button,
} from "antd";
import React from "react";
import { EyeOutlined } from "@ant-design/icons";

const AppBannerView = (props) => {
  const { data, visible, close } = props;

  return (
    <Drawer
      width={380}
      placement="right"
      closable={false}
      onClose={close}
      open={visible}
      className="banner-drawer"
      bodyStyle={{ padding: 0 }}
    >
      <Card title={data?.name || "Untitled Banner"} style={{ height: "95%" }}>
        <Image.PreviewGroup>
          {data && data.image ? (
            <div className="masonry-grid">
              <div className={`masonry-grid-item}`}>
                <Image
                  width="100%"
                  src={data.image}
                  style={{
                    borderRadius: "8px",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  className="gallery-image-masonry"
                  preview={{
                    mask: <EyeOutlined />,
                  }}
                />
              </div>
            </div>
          ) : (
            <Row gutter={[16, 16]} style={{ justifyContent: "center" }}>
              <Empty
                description="No Images Found"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ marginTop: "20px" }}
              />
            </Row>
          )}
        </Image.PreviewGroup>
        <Col span={14}>
          <a href={data.link} target="_blank" rel="noopener noreferrer">
            <Button type="link" style={{ color: "black" }}>
              <EyeOutlined />
              Banner Link
            </Button>
          </a>
        </Col>
      </Card>
    </Drawer>
  );
};

export default AppBannerView;
