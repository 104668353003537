import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../Layouts/Layout";
import { Button, Card, Empty, Input, Skeleton, Table, Tag } from "antd";
import CustomAvatar from "../../Components/common/Avatar";
import Flex from "../../Components/Ui/Flex";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExportVisitedMembers,
  fetchVisitedMembers,
} from "../../store/api/fetchVisitedMember";
import { debounce } from "lodash";
import * as XLSX from "xlsx";
import {
  ExportOutlined,
  LeftCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { fetchEvent } from "../../store/api/fetchEvent";
import { clearEvent } from "../../store/feature/event/eventSlice";
import { clearVisitedMembers } from "../../store/feature/visited-members/visitedMembersSlice";
import { formatFullName, roleMap } from "../../utils/date";
import { clearExportVisitedMembers } from "../../store/feature/visited-members/exportVisitedMembersSlice";

const VisitedMembers = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 100;

  const { data, total, isLoading } = useSelector(
    (state) => state.visitedMembersReducer
  );
  const { data: exportData, isLoading: isExportLoading } = useSelector(
    (state) => state.exportVisitedMemberReducer
  );

  const { data: event, isLoading: isEventLoading } = useSelector(
    (state) => state.eventReducer
  );

  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [isLoadingBoth, setIsLoadingBoth] = useState(true);

  const fetchData = () => {
    dispatch(
      fetchVisitedMembers({
        event_id: id,
        attendanceDate: selectedDate,
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        ...(sortField && { sortBy: sortField }),
        ...(sortOrder && { orderTo: sortOrder }),
        ...(searchTerm && { searchText: searchTerm }),
      })
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchEvent({ id }));
    }
    return () => {
      dispatch(clearEvent());
      dispatch(clearVisitedMembers());
      dispatch(clearExportVisitedMembers());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (event && event.startDate && event.endDate) {
      const today = new Date();
      const startDate = new Date(event.startDate);
      const endDate = new Date(event.endDate);

      if (today >= startDate && today <= endDate) {
        const formattedToday = today.toISOString().split("T")[0];
        setSelectedDate(formattedToday);
      } else {
        setSelectedDate(event.startDate.split(" ")[0]);
      }
    }
  }, [event]);

  useEffect(() => {
    if (selectedDate) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (event && id) {
      dispatch(clearVisitedMembers());
      fetchData();
    }
  }, [currentPage, sortOrder, sortField, searchTerm, selectedDate]);

  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [selectedDate]);

  useEffect(() => {
    if (!isEventLoading && !isLoading && selectedDate && data) {
      setIsLoadingBoth(false);
    }
  }, [isEventLoading, isLoading, event, data]);

  const tableColumns = useMemo(
    () => [
      {
        title: "Membership ID",
        dataIndex: "id",
        render: (_, record) => record.membershipCode,
        sorter: true,
      },
      {
        title: "Member Name",
        dataIndex: "firstName",
        render: (_, record) => (
          <div className="d-flex align-items-center">
            <CustomAvatar
              name={record.firstName}
              surname={record.lastName}
              profile={record.photo}
              size={50}
              style={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <span className="ml-2" style={{ textTransform: "capitalize" }}>
              {`${record.firstName} ${record.middelName || ""} ${
                record.lastName
              }`}
            </span>
          </div>
        ),
        sorter: true,
      },
      {
        title: "Member Phone",
        dataIndex: "mobileNumber",
        render: (_, record) => record.mobileNumber,
      },
      {
        title: "Verified By",
        dataIndex: "verifiedBy",
        render: (_, record) => (
          <span className="ml-2" style={{ textTransform: "capitalize" }}>
            {`${record.verifyByFirstName || ""} ${
              record.verifyByLastName || ""
            }`}
          </span>
        ),
      },
    ],
    []
  );

  const onDiscard = () => {
    dispatch(clearEvent());
    navigate("/events");
  };

  const handleTableChange = (pagination, _filter, sorter) => {
    setCurrentPage(pagination.current);
    if (sorter && sorter.field && sorter.order) {
      setSortField(sorter.field);
      setSortOrder(sorter.order === "ascend" ? "ASC" : "DESC");
    } else {
      setSortField("");
      setSortOrder("");
    }
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      setSearchTerm(value);
    }, 300),
    []
  );

  const handleSearch = (e) => {
    debounceSearch(e.target.value);
  };

  const skeletonColumns = useMemo(
    () =>
      tableColumns.map((col) => ({
        ...col,
        render: () => (
          <Skeleton.Button style={{ width: "150px", height: 32 }} active />
        ),
      })),
    [tableColumns]
  );

  const skeletonData = useMemo(
    () =>
      Array.from({ length: 5 }, (_, index) => ({ key: `skeleton-${index}` })),
    []
  );

  const handleExportData = async () => {
    dispatch(
      fetchExportVisitedMembers({
        event_id: id,
        attendanceDate: selectedDate || event?.startDate,
      })
    );
  };
  useEffect(() => {
    if (exportData && exportData.length > 0) {
      exportToExcel(exportData);
    }
  }, [exportData]);

  const exportToExcel = (data) => {
    const transformedData = data.map((item) => ({
      membershipCode: item.membershipCode,
      fullName: formatFullName(item.firstName, item.middelName, item.lastName),
      mobileNumber: item.mobileNumber,
      gender:
        item.gender === "1" ? "Female" : item.gender === "0" ? "Male" : "",
      memberRelation: roleMap[item.memberRelationId] || "Unknown",
      photo: item.photo,
      verifiedBy: formatFullName(
        item.verifyByFirstName,
        "",
        item.verifyByLastName
      ),
      attendanceDate: item.attendanceDate,
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    const headers = [
      "Membership Code",
      "Full Name",
      "Mobile Number",
      "Gender",
      "Member Relation",
      "Photo",
      "Verified By",
      "Attendance Date",
    ];

    headers.forEach((header, index) => {
      worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = { v: header };
    });

    const maxWidths = headers.map((_, i) =>
      Math.max(
        ...transformedData.map(
          (row) =>
            (row[headers[i].toLowerCase().replace(/ /g, "")] || "").toString()
              .length
        ),
        headers[i].length
      )
    );

    worksheet["!cols"] = maxWidths.map((width) => ({ wch: width }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Members");

    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, "0")}-${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${today.getFullYear()}`;

    const fileName = `${
      event?.name || ""
    }-Attendance-Members-${formattedDate}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  return (
    <Layout>
      <Card>
        <Flex
          className="py-2"
          mobileFlex={false}
          justifyContent="between"
          alignItems="center"
        >
          <h2>Visited Member's list</h2>
          <Flex className="mb-1" mobileFlex={false}>
            <Button
              type="secondary"
              className="mr-2"
              onClick={() => onDiscard()}
            >
              BACK
              <LeftCircleOutlined />
            </Button>
            <div className="addButton">
              <Button
                link
                loading={isExportLoading}
                style={{
                  marginLeft: "10px",
                  background:
                    "linear-gradient(120deg, #b3e07b 0%, #7dbf5a 100%)",
                  color: "white",
                  fontWeight: "500",
                  // boxShadow: "0 4px 10px rgba(123, 182, 98, 0.6)",
                  transition: "background 0.3s ease",
                  border: "none",
                }}
                onClick={handleExportData}
              >
                Export Data <ExportOutlined />
              </Button>
            </div>
          </Flex>
        </Flex>
        <Flex justifyContent="between" mobileFlex={false} mb={2}>
          <Flex className="mb-1" mobileFlex={false}>
            <div className="mr-3">
              <Input
                defaultValue={searchTerm}
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={handleSearch}
                allowClear
              />
            </div>
            <div className="mr-3">
              <Input
                type="date"
                style={{ width: 200 }}
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                  setCurrentPage(1);
                }}
                min={event?.startDate.split(" ")[0]}
                max={event?.endDate.split(" ")[0]}
              />
            </div>
          </Flex>
          <Flex className="mb-1" mobileFlex={false} alignItems="end">
            <div>
              <Tag color="green">{event?.name}</Tag>
              <Tag color={total < 1 ? "red" : "green"}>
                {`Total Results Found: `} {`${total}`}
              </Tag>
            </div>
          </Flex>
        </Flex>
        <div className="table-responsive">
          {isLoadingBoth || !data ? (
            <Table columns={skeletonColumns} dataSource={skeletonData} />
          ) : data?.length === 0 ? (
            <Table
              columns={tableColumns}
              dataSource={data}
              rowKey="id"
              locale={{
                emptyText: <Empty description="No Data Available" />,
              }}
            />
          ) : (
            <Table
              columns={isLoadingBoth ? skeletonColumns : tableColumns}
              dataSource={isLoadingBoth ? skeletonData : data}
              pagination={{
                current: currentPage,
                pageSize,
                total: total,
                showSizeChanger: false,
              }}
              onChange={handleTableChange}
            />
          )}
        </div>
      </Card>
    </Layout>
  );
};

export default VisitedMembers;
