import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tabs, Form, Button, Card, message, Spin } from "antd";
import Flex from "../../Components/Ui/Flex";
import Layout from "../../Layouts/Layout";
import GeneralForm from "../../Components/member/GeneralForm";
import FamilyMemberForm from "../../Components/member/FamilyMemberForm";
import AdminOnlyForm from "../../Components/member/AdminOnlyForm";
import { calculateAge } from "../../utils/date";
import { useDispatch, useSelector } from "react-redux";
import { fetchPincodeDetails } from "../../store/api/fetchPostal";
import { debounce } from "lodash";
import { fetchFamilyMembers } from "../../store/api/fetchFamilyMembers";
import { updateMember } from "../../store/api/UpdateMember";
import { clearFamilyMembersData } from "../../store/feature/family-members/familyMembersSlice";
import { getBase64 } from "../../utils/file";

const { TabPane } = Tabs;

const EditMembers = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [sameAsPostal, setSameAsPostal] = useState(true);
  const [initialValues, setInitialValues] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { searchTerm, sortField, sortOrder, page } = location.state || {};
  const { data: familyMembers, isLoading } = useSelector(
    (state) => state.familyMembersReducer
  );
  const { data } = useSelector((state) => state.pincodeReducer);
  const { isUpdatingLoading } = useSelector(
    (state) => state.updateMemberReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (familyMembers) {
      const updatedMembers = familyMembers.members.map((u) => ({
        ...u,
        age: calculateAge(u.dateOfBirth),
      }));
      setSameAsPostal(
        updatedMembers.filter((u) => u.memberRelationId === "1")[0]
          .sameAsPostalAddress === "1"
      );

      const initialValues = {
        ...familyMembers,
        ...updatedMembers.filter((u) => u.memberRelationId === "1")[0],
        members: updatedMembers.filter((u) => u.memberRelationId !== "1"),
        registrationDate: familyMembers.registrationDate.split(" ")[0],
      };
      setInitialValues(initialValues);
    }
  }, [familyMembers, id]);

  useEffect(() => {
    setInitialValues(null);
    if (id) {
      dispatch(fetchFamilyMembers({ membership_id: id }));
    }
  }, [id]);

  useEffect(() => {
    if (initialValues) {
      const { pinCode, permanentAddressPinCode } = initialValues;

      if (pinCode) {
        dispatch(fetchPincodeDetails({ pincode_id: pinCode }))
          .unwrap()
          .then(({ District: city, State: state }) => {
            form.setFieldsValue({ city, state });

            if (sameAsPostal) {
              form.setFieldsValue({
                permanentAddressCity: city,
                permanentAddressState: state,
              });
            }
          })
          .catch((error) => {
            console.error(
              "Failed to fetch pincode details for pinCode:",
              error
            );
          });
      }

      if (permanentAddressPinCode && !sameAsPostal) {
        dispatch(fetchPincodeDetails({ pincode_id: permanentAddressPinCode }))
          .unwrap()
          .then(({ District: city, State: state }) => {
            form.setFieldsValue({
              permanentAddressCity: city,
              permanentAddressState: state,
            });
          })
          .catch((error) => {
            console.error(
              "Failed to fetch pincode details for permanentAddressPinCode:",
              error
            );
          });
      }
    }
  }, [initialValues]);

  useEffect(() => {
    const { District: city, State: state } = data;
    if (data && sameAsPostal) {
      form.setFieldsValue({ city, state });
      form.setFieldsValue({
        permanentAddressCity: city,
        permanentAddressState: state,
      });
    } else if (data && !sameAsPostal) {
      form.setFieldsValue({
        permanentAddressCity: city,
        permanentAddressState: state,
      });
    }
  }, [data]);

  const dispatchDebouncedPincodeDetails = debounce((pincode) => {
    dispatch(fetchPincodeDetails({ pincode_id: pincode }));
  }, 1000);

  const onDiscard = () => {
    navigate("/members", {
      state: {
        searchTerm,
        sortField,
        sortOrder,
        page,
      },
    });
    form.resetFields();
  };

  const onFinish = (values) => {
    const {
      membershipCode,
      introducerCode,
      seconderCode,
      registrationDate,
      guestMemberCardNumber,
      membershipRemarks,
      members = [],
      ...rest
    } = values;
    const newValues = {
      id: id,
      membershipTypeId: "1",
      membershipCode,
      introducerCode,
      seconderCode,
      registrationDate,
      guestMemberCardNumber,
      membershipRemarks,
      members: [
        {
          ...rest,
          id: initialValues.id,
          memberRelationId: "1",
          sameAsPostalAddress: sameAsPostal ? "1" : "0",
        },
        ...members,
      ].map((m) => {
        if (m.email) {
          return { ...m, email: m.email.toLowerCase() };
        } else {
          return m;
        }
      }),
    };

    const updatedPhoto = {
      ...newValues,
      members: newValues.members.map((m, i) => {
        const newData = { ...m };
        if (
          familyMembers?.members?.find((m) => m.id === newData.id)?.photo ===
          newData?.photo
        ) {
          delete newData.photo;
        }
        return newData;
      }),
    };

    dispatch(updateMember(updatedPhoto))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("Member successfully updated!");
          dispatch(clearFamilyMembersData());
          navigate("/members", {
            state: {
              searchTerm,
              sortField,
              sortOrder,
              page,
            },
          });
        } else {
          message.error(response.Message);
        }
      })
      .catch((error) => {
        message.error("Failed to update member. Please try again.");
        console.error("Update member error:", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const onFormValuesChange = (changedValues) => {
    const { firstName, middelName, lastName } = form.getFieldsValue([
      "firstName",
      "middelName",
      "lastName",
    ]);
    if (changedValues.dateOfBirth) {
      const age = calculateAge(changedValues.dateOfBirth);
      form.setFieldsValue({ age });
    }

    if (changedValues.pinCode) {
      const pincode = changedValues.pinCode;
      dispatchDebouncedPincodeDetails(pincode);
    }

    if (!sameAsPostal && changedValues.permanentAddressPinCode) {
      const pincode = changedValues.permanentAddressPinCode;
      dispatchDebouncedPincodeDetails(pincode);
    }
    if (sameAsPostal) {
      const { postalAddress, city, state, pinCode } = form.getFieldsValue([
        "postalAddress",
        "city",
        "state",
        "pinCode",
      ]);

      form.setFieldsValue({
        permanentAddress: postalAddress,
        permanentAddressCity: city,
        permanentAddressState: state,
        permanentAddressPinCode: pinCode,
      });
    }

    if (
      changedValues.firstName ||
      changedValues.middelName ||
      changedValues.lastName
    ) {
      const members = form.getFieldValue("members") || [];

      const updatedFamilyMembers = members.map((member) => {
        const updatedMember = { ...member };

        if (member?.memberRelationId) {
          const relation = member.memberRelationId;

          switch (relation) {
            case "2":
              updatedMember.firstName = member.firstName;
              updatedMember.middelName = member.middelName;
              updatedMember.lastName = lastName || "";
              updatedMember.gender = member.gender;
              break;
            case "3":
              updatedMember.firstName = member.firstName;
              updatedMember.lastName = lastName || "";
              updatedMember.middelName = firstName || "";
              updatedMember.gender = "0";
              break;
            case "4":
              updatedMember.firstName = member.firstName;
              updatedMember.middelName = firstName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = "1";
              break;
            case "5":
              updatedMember.firstName = middelName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.middelName = member.middelName;
              updatedMember.gender = "0";
              break;
            case "6":
              updatedMember.firstName = member.firstName;
              updatedMember.middelName = middelName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = "1";
              break;
            default:
              break;
          }
        }

        return updatedMember;
      });

      form.setFieldsValue({ members: updatedFamilyMembers });
    }

    if (changedValues.members) {
      const members = form.getFieldValue("members") || [];
      const changedFamilyMembers = changedValues.members;

      const updatedFamilyMembers = members.map((member, index) => {
        const updatedMember = { ...member };

        if (changedFamilyMembers[index]?.memberRelationId) {
          const relation = changedFamilyMembers[index].memberRelationId;

          switch (relation) {
            case "2":
              updatedMember.firstName =
                changedFamilyMembers[index].firstName || "";
              updatedMember.middelName =
                changedFamilyMembers[index].middelName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = changedFamilyMembers[index].gender || "";
              break;
            case "3":
              updatedMember.firstName =
                changedFamilyMembers[index].firstName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.middelName = firstName || "";
              updatedMember.gender = "0";
              break;
            case "4":
              updatedMember.firstName =
                changedFamilyMembers[index].firstName || "";
              updatedMember.middelName = firstName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = "1";
              break;
            case "5":
              updatedMember.firstName = middelName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.middelName =
                changedFamilyMembers[index].middelName || "";
              updatedMember.gender = "0";
              break;
            case "6":
              updatedMember.firstName =
                changedFamilyMembers[index].firstName || "";
              updatedMember.middelName = middelName || "";
              updatedMember.lastName = lastName || "";
              updatedMember.gender = "1";
              break;
            default:
              break;
          }
        }

        return updatedMember;
      });

      form.setFieldsValue({ members: updatedFamilyMembers });
    }
  };

  const switchOnChange = (checked) => {
    setSameAsPostal(checked);

    if (checked) {
      const { postalAddress, city, state, pinCode } = form.getFieldsValue([
        "postalAddress",
        "city",
        "state",
        "pinCode",
      ]);

      form.setFieldsValue({
        permanentAddress: postalAddress,
        permanentAddressCity: city,
        permanentAddressState: state,
        permanentAddressPinCode: pinCode,
      });
    } else {
      form.setFieldsValue({
        permanentAddress: "",
        permanentAddressState: "",
        permanentAddressCity: "",
        permanentAddressPinCode: "",
      });
    }
  };

  const beforeUpload = (file) => {
    const isValidFileType = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/webp",
    ].includes(file.type);
    if (!isValidFileType) {
      message.error("You can only upload JPG, JPEG, PNG, or WEBP files!");
    }
    return false;
  };

  const handleChange = async ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[fileList.length - 1];
      const base64 = await getBase64(file.originFileObj);

      form.setFieldsValue({
        photo: base64,
      });
    }
  };

  const handleRemove = () => {
    form.setFieldsValue({
      photo: initialValues?.photo || null,
    });
  };
  return (
    <>
      <Layout>
        <Card>
          {isLoading || !initialValues ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <Form
              form={form}
              layout="vertical"
              onValuesChange={onFormValuesChange}
              name="advanced_search"
              className="ant-advanced-search-form"
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div className="container">
                <Flex
                  className="py-2"
                  mobileFlex={false}
                  justifyContent="between"
                  alignItems="center"
                >
                  <h2 className="mb-3">Update Member</h2>
                  <div className="mb-3">
                    <Button className="mr-2" onClick={() => onDiscard()}>
                      Discard
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isUpdatingLoading}
                    >
                      Update
                    </Button>
                  </div>
                </Flex>
              </div>
              <div className="container mt-0">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Main Member Basic" key="1">
                    <GeneralForm
                      form={form}
                      sameAsPostal={sameAsPostal}
                      switchOnChange={switchOnChange}
                      beforeUpload={beforeUpload}
                      handleChange={handleChange}
                      initialValues={initialValues}
                      handleRemove={handleRemove}
                    ></GeneralForm>
                  </TabPane>
                  <TabPane tab="Family Members" key="3" forceRender>
                    <FamilyMemberForm form={form}></FamilyMemberForm>
                  </TabPane>
                  <TabPane tab="Admin Only" key="2" forceRender>
                    <AdminOnlyForm form={form}></AdminOnlyForm>
                  </TabPane>
                </Tabs>
              </div>
            </Form>
          )}
        </Card>
      </Layout>
    </>
  );
};

export default EditMembers;
