import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const addStaff = createAsyncThunk(
  "staffs/addStaff",
  async (staffData, thunkAPI) => {
    try {
      const response = await instance.post("/backyardUser", staffData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to add staff"
      );
    }
  }
);
