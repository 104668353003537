import React from "react";
import { Card, Row, Col, Typography } from "antd";
import QRCodeForm from "../../Components/qr-code/QRCodeForm";

const backgroundStyle = {
  background: `
    linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    ),
    url(/img/others/club-oliv-day.webp)`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  minHeight: "100vh",
  height: "100vh",
  overflow: "hidden",
  // padding: "20px 0",
};

const theme = "light";

export default function QrCode(props) {
  const { Title, Text, Paragraph } = Typography;
  return (
    <div style={backgroundStyle}>
      <Row
        justify="center"
        // align="middle"
        style={{ top: "5%",position: "relative", minHeight: "100vh", height: "100vh" }} // Ensures the row takes up the full height of the viewport
      >
        <Col xs={16} sm={16} md={16} lg={16}>
          <Card>
            <div className="">
              <div className="text-center">
                <img
                  className="img-fluid pb-3"
                  width={100}
                  src={`/img/${
                    theme === "light" ? "logo-dark.png" : "logo-dark.png"
                  }`}
                  alt="Logo"
                />
                <Title
                level={5}
                >Oliv Club - Suvarna Navratri 2024 QR Code Generation</Title>
                 <Paragraph type="secondary" style={{fontSize: "10px"}}>You’ll be able to download your QR Code, which is required for entry through the Oliv Club Lounge Area.</Paragraph>
              </div>
              <Row justify="center">
                <Col xs={24} sm={22} md={20} lg={18}>
                  <QRCodeForm />
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
