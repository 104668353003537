import { createSlice } from "@reduxjs/toolkit";
import { deleteGalleryImage } from "../../api/DeleteGalleryImage";

const initialState = {
  data: null,
  isDeletingLoading: false,
};

export const deleteGalleryImageSlice = createSlice({
  name: "deleteGalleryImage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteGalleryImage.pending, (state) => {
        state.isDeletingLoading = true;
      })
      .addCase(deleteGalleryImage.fulfilled, (state, action) => {
        state.isDeletingLoading = false;
        state.data = action.payload;
      })
      .addCase(deleteGalleryImage.rejected, (state) => {
        state.isDeletingLoading = false;
        state.data = null;
      });
  },
});

export default deleteGalleryImageSlice.reducer;
