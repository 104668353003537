/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../Layouts/Layout";
import {
  Button,
  Card,
  Input,
  message,
  Popconfirm,
  Skeleton,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import Flex from "../../Components/Ui/Flex";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaffs } from "../../store/api/fetchStaffs";
import CustomAvatar from "../../Components/common/Avatar";
import StaffView from "../../Components/staff/StaffView";
import { deleteStaff } from "../../store/api/DeleteStaff";
import { getUserData } from "../../utils/token";

export default function Staff() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = getUserData();

  const [selectedStaff, setSelectedStaff] = useState(null);
  const [staffVisible, setStaffVisible] = useState(false);

  const { data, isLoading } = useSelector((state) => state.staffsReducer);
  const { isDeletingLoading } = useSelector(
    (state) => state.deleteStaffReducer
  );

  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = () => {
    dispatch(
      fetchStaffs({
        ...(sortField && { sortBy: sortField }),
        ...(sortOrder && { orderTo: sortOrder }),
        ...(searchTerm && { searchText: searchTerm }),
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [sortOrder, sortField, searchTerm]);

  const handleTableChange = (_pagination, _filter, sorter) => {
    if (sorter && sorter.field && sorter.order) {
      setSortField(sorter.field);
      setSortOrder(sorter.order === "ascend" ? "ASC" : "DESC");
    } else {
      setSortField("");
      setSortOrder("");
    }
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 300),
    []
  );

  const handleSearch = (e) => {
    debounceSearch(e.target.value);
  };

  const addStaffRoute = () => {
    navigate("/staff/add-staff");
  };

  const editStaffRoute = (id) => {
    navigate(`/staff/edit-staff/${id}`);
  };

  const showStaff = (event) => {
    setStaffVisible(true);
    setSelectedStaff(event);
  };

  const closeStaff = () => {
    setStaffVisible(false);
    setSelectedStaff(null);
  };

  function confirm(e) {
    dispatch(deleteStaff(e))
      .then(() => {
        message.success("Staff deleted successfully");
        fetchData();
      })
      .catch((error) => {
        message.error("Failed to delete staff: " + error.message);
      });
  }

  function cancel(e) {}

  const tableColumns = useMemo(
    () => [
      {
        title: "Staff ID",
        dataIndex: "id",
        render: (_, record) => record.id,
        sorter: true,
      },
      {
        title: "Staff Name",
        dataIndex: "firstName",
        render: (_, record) => (
          <div className="d-flex align-items-center">
            <CustomAvatar
              name={record.firstName}
              surname={record.lastName}
              profile={record.profilePicture}
              size={30}
              style={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <span className="ml-2" style={{ textTransform: "capitalize" }}>
              {`${record.firstName} ${record.lastName}`}
            </span>
          </div>
        ),
        sorter: true,
      },
      {
        title: "Staff Phone",
        dataIndex: "phoneNumber",
        render: (_, record) => record.phoneNumber,
      },
      {
        title: "Staff Email",
        dataIndex: "email",
        render: (_, record) => record.email,
      },
      {
        title: "Staff Role",
        dataIndex: "backyardUserRole",
        render: (_, record) => (
          <Tag color="green">{record.backyardUserRole}</Tag>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (_, elm) => (
          <div className="text-right d-flex justify-content-start">
            <Tooltip title="View">
              <Button
                type="info"
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => showStaff(elm)}
                size="small"
              />
            </Tooltip>

            <Tooltip title="Update">
              <Button
                type="info"
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => editStaffRoute(elm.id)}
                size="small"
                disabled={
                  elm.backyardUserRoleId === "4" &&
                  userData.backyardUserRoleId === "1"
                }
              />
            </Tooltip>
            <Popconfirm
              placement="bottomLeft"
              title={`Are you sure to delete ${elm.firstName}?`}
              onConfirm={() => confirm(elm.id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button
                  style={{ color: "red" }}
                  icon={<DeleteOutlined />}
                  size="small"
                />
              </Tooltip>
            </Popconfirm>
          </div>
        ),
      },
    ],
    [showStaff, editStaffRoute, confirm, cancel]
  );

  const skeletonColumns = useMemo(
    () =>
      tableColumns.map((col) => ({
        ...col,
        render: () => (
          <Skeleton.Button style={{ width: "150px", height: 32 }} active />
        ),
      })),
    [tableColumns]
  );

  const skeletonData = useMemo(
    () =>
      Array.from({ length: 5 }, (_, index) => ({ key: `skeleton-${index}` })),
    []
  );
  return (
    <Layout>
      <Card>
        <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
          <Flex className="mb-1" mobileFlex={false}>
            <div className="mr-md-3 mb-3">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={handleSearch}
                allowClear
              />
            </div>
            {data && (
              <h5 className="d-flex align-items-center">
                <Tag color={data.length < 1 ? "red" : "green"}>
                  {`Total Results Found: `} {`${data.length}`}
                </Tag>
              </h5>
            )}
          </Flex>
          <div className="addButton">
            <Button
              onClick={addStaffRoute}
              type="primary"
              icon={<PlusCircleOutlined />}
              block
            >
              Add Staff
            </Button>
          </div>
        </Flex>
        <div className="table-responsive">
          <Table
            columns={
              isLoading || isDeletingLoading ? skeletonColumns : tableColumns
            }
            dataSource={isLoading || isDeletingLoading ? skeletonData : data}
            rowKey="id"
            pagination={false}
            onChange={handleTableChange}
          />
        </div>
        {selectedStaff && (
          <StaffView
            data={selectedStaff}
            visible={staffVisible}
            loading={true}
            close={() => {
              closeStaff();
            }}
          />
        )}
      </Card>
    </Layout>
  );
}
