import React from "react";
import Layout from "../../Layouts/Layout";
import { Button, Card, Form, message, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import Flex from "../../Components/Ui/Flex";
import { getBase64 } from "../../utils/file";
import StaffForm from "../../Components/staff/StaffForm";
import { useDispatch, useSelector } from "react-redux";
import { addStaff } from "../../store/api/AddStaff";

const AddStaff = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAddingLoading } = useSelector((state) => state.addStaffReducer);

  const onDiscard = () => {
    navigate("/staff");
  };

  const onFinish = (values) => {
    const payload = { ...values, email: values.email?.toLowerCase() };

    if (!payload.profilePicture) {
      delete payload.profilePicture;
    }
    dispatch(addStaff(payload))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("Staff successfully added!");
          navigate("/staff");
        } else {
          message.error(response.Message);
        }
      })
      .catch((error) => {
        message.error("Failed to add member. Please try again.");
        console.error("Add member error:", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const beforeUpload = (file) => {
    const isValidFileType = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/jpg",
    ].includes(file.type);

    if (!isValidFileType) {
      message.error("You can only upload JPG, PNG, or WEBP files!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = async ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[fileList.length - 1];
      const base64 = await getBase64(file.originFileObj);

      form.setFieldsValue({
        profilePicture: base64,
      });
    }
  };

  const handleRemove = () => {
    form.setFieldsValue({
      profilePicture: null,
    });
  };

  const onFormValuesChange = (changedValues) => {
    if (
      changedValues.profilePicture &&
      typeof changedValues.profilePicture !== "string"
    ) {
      handleRemove();
    }
  };
  return (
    <>
      <Layout>
        <Card>
          <Form
            form={form}
            layout="vertical"
            name="advanced_search"
            className="ant-advanced-search-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onFormValuesChange}
          >
            <div className="container">
              <Flex
                className="py-2"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">Add New Staff</h2>
                <div className="mb-3">
                  <Button className="mr-2" onClick={() => onDiscard()}>
                    Discard
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isAddingLoading}
                  >
                    Add
                  </Button>
                </div>
              </Flex>
            </div>
            <div className="container mt-0">
              <StaffForm
                form={form}
                beforeUpload={beforeUpload}
                handleChange={handleChange}
                handleRemove={handleRemove}
              />
            </div>
          </Form>
        </Card>
      </Layout>
    </>
  );
};

export default AddStaff;
