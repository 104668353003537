import { Card, Col, Form, Input, Row } from "antd";
import React from "react";

const ChangePasswordForm = () => {
  return (
    <Row gutter={24}>
      <Col xs={24} sm={24} md={24}>
        <Card title="Upgrade Your Password">
          <Form.Item
            label="Current Password"
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Please enter your currrent password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please enter your new password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Password not matched!");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Card>
      </Col>
    </Row>
  );
};

export default ChangePasswordForm;
