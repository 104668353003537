import { createSlice } from "@reduxjs/toolkit";
import { fetchExportVisitedMembers } from "../../api/fetchVisitedMember";
import { message } from "antd";

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
  error: "",
};
export const exportVisitedMemberSlice = createSlice({
  name: "exportVisitedMembers",
  initialState,
  reducers: {
    clearExportVisitedMembers: (state) => {
      state.data = [];
      state.total = 0;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExportVisitedMembers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExportVisitedMembers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        if(action.payload.Data.length === 0){
            message.error("No data available for export.");
        }
        state.data = action.payload.Data;
        state.total = action.payload.Total;
      })
      .addCase(fetchExportVisitedMembers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch data";
      });
  },
});

export const { clearExportVisitedMembers } = exportVisitedMemberSlice.actions;

export default exportVisitedMemberSlice.reducer;
