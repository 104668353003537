import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const deleteMember = createAsyncThunk(
  "members/deleteMembers",
  async (id, thunkAPI) => {
    try {
      const response = await instance.delete(`/membership/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to delete member"
      );
    }
  }
);
