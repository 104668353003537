import React from "react";
import SideBar from "./SideBar";
import NavProfile from "./NavProfile";
import { Link } from "react-router-dom";

export default function Layout({ children }) {
  const navMode = "light";

  return (
    <>
      <div
        className={`app-header ${navMode}`}
        style={{ backgroundColor: "#fff" }}
      >
        <div className={`app-header-wrapper layout-top-nav`} style={{justifyContent: "space-between"}}>
         <Link to="/members"> <img className="img-fluid p-3" width={100} src="/img/logo-dark.png"/> </Link>
          <div className="nav" style={{ width: `calc(100% - 250})` }}>
            <div className="nav-left">
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                <li className="ant-menu-item ant-menu-item-only-child"></li>
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  style={{ cursor: "auto" }}
                >
                  {/* <SearchInput mode={mode} isMobile={isMobile} /> */}
                </li>
              </ul>
            </div>
            <div className="nav-right">
              {/* <NavNotification />
            <NavLanguage /> */}
              <NavProfile />
              {/* <NavPanel direction={direction} /> */}
            </div>
            {/* <NavSearch active={searchActive} close={onSearchClose}/> */}
          </div>
        </div>
      </div>
      <div className="app-container">
        <SideBar></SideBar>
      </div>
      <section
        className="ant-layout app-layout"
        style={{ paddingLeft: "250px" }}
      >
        <div className="app-content">
          <main className="ant-layout-content">{children}</main>
        </div>
      </section>
    </>
  );
}
