import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../Layouts/Layout";
import {
  Button,
  Card,
  Input,
  message,
  Popconfirm,
  Skeleton,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Flex from "../../Components/Ui/Flex";
import { useDispatch, useSelector } from "react-redux";
import { fetchGallery } from "../../store/api/fetchGallery";
import { useNavigate } from "react-router-dom";
import GalleryView from "../../Components/gallery/GalleryView";
import { deleteGallery } from "./../../store/api/deleteGallery/index";
import { debounce } from "lodash";

const Gallery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [galleryVisible, setGalleryVisible] = useState(false);
  const { data, isLoading } = useSelector((state) => state.galleryReducer);
  const [searchTerm, setSearchTerm] = useState("");

  const addGalleryRoute = () => {
    navigate(`/gallery/add-gallery`);
  };

  const editGalleryRoute = (id) => {
    navigate(`/gallery/edit-gallery/${id}`);
  };

  const tableColumns = [
    {
      title: "Gallery Name",
      dataIndex: "name",
      render: (_, record) => (
        <div className="d-flex align-items-center ">
          <span className="ml-2">{record.name}</span>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right d-flex justify-content-start">
          <Tooltip title="View">
            <Button
              type="info"
              className="mr-2"
              icon={<EyeOutlined />}
              onClick={() => {
                showGallery(elm.id);
              }}
              size="small"
            />
          </Tooltip>
          <Tooltip title="Update">
            <Button
              type="info"
              className="mr-2"
              icon={<EditOutlined />}
              onClick={() => editGalleryRoute(elm.id)}
              size="small"
            />
          </Tooltip>
          <Popconfirm
            placement="bottomLeft"
            title={`Are you sure to delete ${elm.name}?`}
            onConfirm={() => confirm(elm.id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button
                style={{ color: "red" }}
                icon={<DeleteOutlined />}
                size="small"
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const fetchData = () => {
    dispatch(
      fetchGallery({
        sortBy: "id",
        orderTo: "ASC",
        ...(searchTerm && { searchText: searchTerm }),
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const showGallery = (id) => {
    setGalleryVisible(true);
    setSelectedGallery(id);
  };

  const closeGallery = () => {
    setGalleryVisible(false);
    setSelectedGallery(null);
  };

  function confirm(e) {
    dispatch(deleteGallery(e))
      .then(() => {
        message.success("Gallery deleted successfully");
        fetchData();
      })
      .catch((error) => {
        message.error("Failed to delete gallery: " + error.message);
      });
  }

  function cancel(e) {
    console.log(e);
  }

  const debounceSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 300),
    []
  );

  const handleSearch = (e) => {
    debounceSearch(e.target.value);
  };

  const skeletonColumns = useMemo(
    () =>
      tableColumns.map((col) => ({
        ...col,
        render: () => (
          <Skeleton.Button style={{ width: "150px", height: 32 }} active />
        ),
      })),
    [tableColumns]
  );

  const skeletonData = useMemo(
    () =>
      Array.from({ length: 5 }, (_, index) => ({ key: `skeleton-${index}` })),
    []
  );
  return (
    <Layout>
      <Card>
        <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
          <Flex className="mb-1" mobileFlex={false}>
            <div className="mr-md-3 mb-3">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={handleSearch}
                allowClear
              />
            </div>
            {data && (
              <h5 className="d-flex align-items-center">
                <Tag color={data.length < 1 ? "red" : "green"}>
                  {`Total Results Found: `} {`${data.length}`}
                </Tag>
              </h5>
            )}
          </Flex>
          <div className="addButton">
            <Button
              onClick={addGalleryRoute}
              type="primary"
              icon={<PlusCircleOutlined />}
              block
            >
              Add Gallery
            </Button>
          </div>
        </Flex>
        <div className="table-responsive">
          <Table
            columns={isLoading ? skeletonColumns : tableColumns}
            dataSource={isLoading ? skeletonData : data}
            rowKey="id"
            pagination={false}
          />
        </div>
        {selectedGallery && (
          <GalleryView
            id={selectedGallery}
            visible={galleryVisible}
            loading={true}
            close={() => closeGallery()}
          />
        )}
      </Card>
    </Layout>
  );
};

export default Gallery;
