import { createSlice } from "@reduxjs/toolkit";
import { fetchUpcomingCode } from "../../api/fetchUpcomingCode";

const initialState = {
  data: null,
  isLoading: false,
  error: "",
};

export const upcomingCodeSlice = createSlice({
  name: "upcoming",
  initialState,
  reducers: {
    clearUpcomingCode: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpcomingCode.pending, (state) => {
        state.isLoading = true;
        state.data = null;
      })
      .addCase(fetchUpcomingCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload.Data.upcomingCode;
      })
      .addCase(fetchUpcomingCode.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch upcoming code";
      });
  },
});

export const { clearUpcomingCode } = upcomingCodeSlice.actions;
export default upcomingCodeSlice.reducer;
