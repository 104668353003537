import React from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  AuditOutlined,
  FileImageOutlined,
  NotificationOutlined,
  PictureOutlined,
  ScanOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { getUserData } from "../utils/token";

const { SubMenu } = Menu;

const SideBar = () => {
  const location = useLocation();
  const sideNavTheme = "light";
  const user = getUserData();
  const navigationConfig = [
    {
      groupTitle: "Developer Only",
      items: [
        user.backyardUserRoleId === "4" && {
          key: "1",
          title: "Push Notification",
          icon: <NotificationOutlined />,
          path: "/push-notification",
        },
        user.backyardUserRoleId === "4" && {
          key: "3",
          title: "App Config",
          icon: <SettingOutlined />,
          path: "/app-config",
        },
      ].filter(Boolean),
    },
    {
      groupTitle: "Admin Only",
      items: [
        (user.backyardUserRoleId === "1" ||
          user.backyardUserRoleId === "4") && {
          key: "8",
          title: "Staff",
          icon: <AuditOutlined />,
          path: "/staff",
        },
        (user.backyardUserRoleId === "1" ||
          user.backyardUserRoleId === "4") && {
          key: "2",
          title: "Application Banner",
          icon: <FileImageOutlined />,
          path: "/app-banner",
        },
      ].filter(Boolean),
    },
    {
      groupTitle: "General",
      items: [
        {
          key: "4",
          title: "Members",
          icon: <UsergroupAddOutlined />,
          path: "/members",
        },
        // {
        //   key: "5",
        //   title: "Members Maintenance",
        //   icon: <IdcardOutlined />,
        //   path: "/maintenance",
        // },
        {
          key: "6",
          title: "Events",
          icon: <ScanOutlined />,
          path: "/events",
        },
        {
          key: "7",
          title: "Gallery",
          icon: <PictureOutlined />,
          path: "/gallery",
        },
      ],
    },
  ].filter((i) => i.items.length);

  const setDefaultOpen = (path) => {
    const currentMenu = navigationConfig
      .flatMap((group) => group.items)
      .find((menu) => {
        if (menu.submenu) {
          return menu.submenu.some((submenu) => {
            return submenu.path && path && path.includes(submenu.path);
          });
        } else {
          return menu.path && path && path.includes(menu.path);
        }
      });

    return currentMenu ? [currentMenu.key] : [];
  };

  const setDefaultSelected = (path) => {
    const currentMenu = navigationConfig
      .flatMap((group) => group.items)
      .find((menu) => {
        if (menu.submenu) {
          return menu.submenu.some((submenu) => {
            return submenu.path && path && path.includes(submenu.path);
          });
        } else {
          return menu.path && path && path.includes(menu.path);
        }
      });

    return currentMenu ? [currentMenu.key] : [];
  };

  const setLocale = (localization, title) => {
    return title;
  };

  const currentPath = location.pathname;

  return (
    <aside className={`ant-layout-sider ${sideNavTheme} side-nav sidebar`}>
      <div className="ant-layout-sider-children">
        <Menu
          mode="inline"
          theme={sideNavTheme}
          defaultSelectedKeys={setDefaultSelected(currentPath)}
          defaultOpenKeys={setDefaultOpen(currentPath)}
        >
          {navigationConfig.map((group, groupIndex) => (
            <Menu.ItemGroup
              className="mt-4"
              key={groupIndex}
              title={group.groupTitle}
            >
              {group.items.map((menu) =>
                menu.submenu && menu.submenu.length > 0 ? (
                  <SubMenu
                    key={menu.key}
                    icon={menu.icon}
                    title={setLocale(null, menu.title)}
                  >
                    {menu.submenu.map((submenu) => (
                      <Menu.Item key={submenu.key} icon={submenu.icon}>
                        <Link to={submenu.path}>
                          {setLocale(null, submenu.title)}
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={menu.key} icon={menu.icon}>
                    <Link to={menu.path}>{setLocale(null, menu.title)}</Link>
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ))}
        </Menu>
      </div>
    </aside>
  );
};

export default SideBar;
