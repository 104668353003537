import { createSlice } from "@reduxjs/toolkit";
import { updateMember } from "../../api/UpdateMember";

const initialState = {
  data: null,
  isUpdatingLoading: false,
};

export const updateMemberSlice = createSlice({
  name: "updateMember",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateMember.pending, (state) => {
        state.isUpdatingLoading = true;
      })
      .addCase(updateMember.fulfilled, (state, action) => {
        state.isUpdatingLoading = false;
        state.data = action.payload;
      })
      .addCase(updateMember.rejected, (state) => {
        state.isUpdatingLoading = false;
        state.data = null;
      });
  },
});

export default updateMemberSlice.reducer;
