import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const updateEvent = createAsyncThunk(
  "events/updateEvent",
  async (eventData, thunkAPI) => {
    try {
      const response = await instance.put(
        `/event/${eventData.id}`,
        eventData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to update event"
      );
    }
  }
);
