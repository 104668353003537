import React, { useEffect } from "react";
import { Card, Row, Col } from "antd";
import LoginForm from "../Components/login/LoginForm";
import { useNavigate } from "react-router-dom";

const backgroundStyle = {
  background: `
    linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5) 
    ),
    url(/img/others/club-oliv-day.webp)`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
const theme = "light";

export default function Login(props) {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("apiKey");

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/members");
    }
  }, [isLoggedIn]);
  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100vh">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className="my-4">
                <div className="text-center">
                  <img
                    className="img-fluid pb-3"
                    width={100}
                    src={`/img/${
                      theme === "light" ? "logo-dark.png" : "logo-dark.png"
                    }`}
                    alt=""
                  />
                </div>
                <Row justify="center">
                  <Col xs={24} sm={20} md={20} lg={20}>
                    <LoginForm {...props} />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
