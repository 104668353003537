import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const addGalleryImage = createAsyncThunk(
  "gallery/addGalleryImage",
  async (data, thunkAPI) => {
    try {
      const response = await instance.post(`/gallery/${data.get("id")}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to add gallery image"
      );
    }
  }
);
