import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const deleteGallery = createAsyncThunk(
  "gallery/deleteGallery",
  async (id, thunkAPI) => {
    try {
      const response = await instance.delete(`/gallery/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to delete gallery"
      );
    }
  }
);
