import { createSlice } from "@reduxjs/toolkit";
import { updateStaff } from "../../api/UpdateStaff";

const initialState = {
  data: null,
  isUpdatingLoading: false,
};

export const updateStaffSlice = createSlice({
  name: "updateStaff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateStaff.pending, (state) => {
        state.isUpdatingLoading = true;
      })
      .addCase(updateStaff.fulfilled, (state, action) => {
        state.isUpdatingLoading = false;
        state.data = action.payload;
      })
      .addCase(updateStaff.rejected, (state) => {
        state.isUpdatingLoading = false;
        state.data = null;
      });
  },
});

export default updateStaffSlice.reducer;
