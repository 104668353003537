import React, { useEffect } from "react";
import Layout from "../../Layouts/Layout";
import { Button, Card, Form, message, Spin, Upload } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Flex from "../../Components/Ui/Flex";
import { useDispatch, useSelector } from "react-redux";
import { getBase64 } from "../../utils/file";
import { fetchBannerById } from "../../store/api/fetchSingleBanner";
import { clearSingleBanner } from "../../store/feature/singleBanner/singleBannerSlice";
import AppBannerForm from "../../Components/app-banner/AppBannerForm";
import { updateBanner } from "../../store/api/updateBanner";

const EditBanner = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { searchTerm, sortField, sortOrder, page } = location.state || {};
  const { data: banner, isLoading } = useSelector(
    (state) => state.singleBannerReducer
  );
  const { isUpdatingLoading } = useSelector(
    (state) => state.updateBannerReducer
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchBannerById({ id }));
    }
  }, [id]);

  const onDiscard = () => {
    dispatch(clearSingleBanner());
    navigate("/app-banner", {
      state: {
        searchTerm,
        sortField,
        sortOrder,
        page,
      },
    });
  };

  const onFinish = (values) => {
    const payload = { id, status: "1", ...values };

    if (values.image === banner.image || !payload.image) {
      delete payload.image;
    }
    if (values.image === banner.image || !payload.image) {
      delete payload.image;
    }
    dispatch(updateBanner(payload))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("Banner successfully updated!");
          dispatch(clearSingleBanner());
          navigate("/app-banner", {
            state: {
              searchTerm,
              sortField,
              sortOrder,
              page,
            },
          });
        } else {
          message.error(response.Message);
        }
      })
      .catch((error) => {
        message.error("Failed to update banner. Please try again.");
        console.error("update banner error:", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const beforeUpload = (file) => {
    const isValidFileType = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/jpg",
    ].includes(file.type);

    if (!isValidFileType) {
      message.error("You can only upload JPG, PNG, or WEBP files!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = async ({ fileList }, key) => {
    if (fileList.length > 0) {
      const file = fileList[fileList.length - 1];
      const base64 = await getBase64(file.originFileObj);

      form.setFieldsValue({
        [key]: base64,
      });
    }
  };

  const handleRemove = (key) => {
    form.setFieldsValue({
      [key]: banner?.[key] || null,
    });
  };

  const onFormValuesChange = (changedValues) => {
    if (
      changedValues.featuredImage &&
      typeof changedValues.featuredImage !== "string"
    ) {
      handleRemove();
    }
    if (
      changedValues.bannerImage &&
      typeof changedValues.bannerImage !== "string"
    ) {
      handleRemove();
    }
  };
  return (
    <>
      <Layout>
        <Card>
          {isLoading || !banner ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <Form
              form={form}
              layout="vertical"
              name="advanced_search"
              onValuesChange={onFormValuesChange}
              className="ant-advanced-search-form"
              initialValues={{
                ...banner,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div className="container">
                <Flex
                  className="py-2"
                  mobileFlex={false}
                  justifyContent="between"
                  alignItems="center"
                >
                  <h2 className="mb-3">Update Event</h2>
                  <div className="mb-3">
                    <Button className="mr-2" onClick={() => onDiscard()}>
                      Discard
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isUpdatingLoading}
                    >
                      Update
                    </Button>
                  </div>
                </Flex>
              </div>
              <div className="container mt-0">
                <AppBannerForm
                  form={form}
                  beforeUpload={beforeUpload}
                  handleChange={handleChange}
                  handleRemove={handleRemove}
                />
              </div>
            </Form>
          )}
        </Card>
      </Layout>
    </>
  );
};

export default EditBanner;
