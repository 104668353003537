import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "./../../../services/axiosService";

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data, thunkAPI) => {
    const { id, ...rest } = data;
    try {
      const response = await instance.put(`/backyardUser/${id}/change-password`, rest);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.Message || "Change Password Failed"
      );
    }
  }
);
