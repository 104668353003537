import React from "react";
import { Form, Button, Card } from "antd";
import Flex from "../../Components/Ui/Flex";
import Layout from "../../Layouts/Layout";

const MembersMaintenance = () => {

  return (
    <>
      <Layout>
        <Card>
          <Form
            layout="vertical"
            name="advanced_search"
            className="ant-advanced-search-form"
          >
            <div className="container">
              <Flex
                className="py-2"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">Member Maintenance</h2>
                <div className="mb-3">
                  {/* <Button type="primary">Add</Button> */}
                </div>
              </Flex>
            </div>
            <div className="container mt-0">
            </div>
          </Form>
        </Card>
      </Layout>
    </>
  );
};

export default MembersMaintenance;
