import { Col, Drawer, Row, Skeleton, Tag, Image, Empty, Card } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGalleryById } from "../../store/api/fetchSingleGallery";
import { EyeOutlined } from "@ant-design/icons";

const GalleryView = (props) => {
  const { id, visible, close } = props;
  const dispatch = useDispatch();

  const { data, isLoading } = useSelector(
    (state) => state.singleGalleryReducer
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchGalleryById({ id }));
    }
  }, [id, dispatch]);

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={close}
      open={visible}
      className="gallery-drawer"
      bodyStyle={{ padding: 0 }}
    >
      {isLoading && !data ? (
        <Skeleton active />
      ) : (
        <Card
          title={data?.name || "Untitled Gallery"}
          style={{ height: "100%" }}
        >
          <Image.PreviewGroup>
            {data && data.images && data.images.length ? (
              <div className="masonry-grid">
                {data.images.map((image) => (
                  <div
                    key={image.id}
                    className={`masonry-grid-item ${image.className || ""}`}
                  >
                    <Image
                      width="100%"
                      src={image.galleryImage}
                      alt={`Gallery Image ${image.id}`}
                      style={{
                        borderRadius: "8px",
                        transition: "transform 0.3s ease",
                        cursor: "pointer",
                      }}
                      className="gallery-image-masonry"
                      preview={{
                        mask: <EyeOutlined />,
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <Row gutter={[16, 16]} style={{ justifyContent: "center" }}>
                <Empty
                  description="No Images Found"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ marginTop: "20px" }}
                />
              </Row>
            )}
          </Image.PreviewGroup>
        </Card>
      )}
    </Drawer>
  );
};

export default GalleryView;
