/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Card,
  Table,
  Tooltip,
  Button,
  Input,
  Tag,
  Popconfirm,
  message,
  Skeleton,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import UserView from "../../Components/Ui/UserView";
import {
  EyeOutlined,
  PlusCircleOutlined,
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import Layout from "../../Layouts/Layout";
import Flex from "../../Components/Ui/Flex";
import { useDispatch, useSelector } from "react-redux";
import { fetchMembers } from "../../store/api/fetchMembers";
import CustomAvatar from "../../Components/common/Avatar";
import debounce from "lodash/debounce";
import { fetchUpcomingCode } from "./../../store/api/fetchUpcomingCode/index";
import { deleteMember } from "../../store/api/DeleteMember";
import { getUserData } from "../../utils/token";
import { exportMembers } from "../../store/api/exportMembers";
import * as XLSX from "xlsx";
import { formatFullName, roleMap } from "../../utils/date";

export default function Members() {
  const user = getUserData();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [userProfileVisible, setUserProfileVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(location.state?.page || 1);
  const pageSize = 100;

  const { data, total, isLoading } = useSelector(
    (state) => state.membersReducer
  );

  const { isDeletingLoading } = useSelector(
    (state) => state.deleteMemberReducer
  );

  const { data: exportData, isLoading: isExportLoading } = useSelector(
    (state) => state.exportMembersReducer
  );

  const [sortField, setSortField] = useState(location.state?.sortField || "");
  const [sortOrder, setSortOrder] = useState(location.state?.sortOrder || "");
  const [searchTerm, setSearchTerm] = useState(
    location.state?.searchTerm || ""
  );

  const fetchData = () => {
    dispatch(
      fetchMembers({
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        ...(sortField && { sortBy: sortField }),
        ...(sortOrder && { orderTo: sortOrder }),
        ...(searchTerm && { searchText: searchTerm }),
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, sortOrder, sortField, searchTerm]);

  const handleTableChange = (pagination, _filter, sorter) => {
    setCurrentPage(pagination.current);
    if (sorter && sorter.field && sorter.order) {
      setSortField(sorter.field);
      setSortOrder(sorter.order === "ascend" ? "ASC" : "DESC");
    } else {
      setSortField("");
      setSortOrder("");
    }
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      setSearchTerm(value);
    }, 300),
    []
  );

  const editMemberRoute = (id) => {
    navigate(`/members/edit-members/${id}`, {
      state: {
        page: currentPage,
        sortField,
        sortOrder,
        searchTerm,
      },
    });
  };
  const handleSearch = (e) => {
    debounceSearch(e.target.value);
  };

  const showUserProfile = (userInfo) => {
    setUserProfileVisible(true);
    setSelectedUser(userInfo);
  };

  const closeUserProfile = () => {
    setUserProfileVisible(false);
    setSelectedUser(null);
  };

  function confirm(e) {
    dispatch(deleteMember(e))
      .then(() => {
        message.success("Member deleted successfully");
        fetchData();
      })
      .catch((error) => {
        message.error("Failed to delete member: " + error.message);
      });
  }

  function cancel(e) {}

  const addMemberRoute = () => {
    navigate("/members/add-members");
    dispatch(fetchUpcomingCode());
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "Membership ID",
        dataIndex: "id",
        render: (_, record) => record.membershipCode,
        sorter: true,
      },
      {
        title: "Member Name",
        dataIndex: "firstName",
        render: (_, record) => (
          <div className="d-flex align-items-center">
            <CustomAvatar
              name={record.firstName}
              surname={record.lastName}
              profile={record.photo}
              size={50}
              style={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <span className="ml-2" style={{ textTransform: "capitalize" }}>
              {`${record.firstName} ${record.middelName} ${record.lastName}`}
            </span>
          </div>
        ),
        sorter: true,
      },
      {
        title: "Member Phone",
        dataIndex: "mobileNumber",
        render: (_, record) => record.mobileNumber,
      },
      {
        title: "Member PAN",
        dataIndex: "panNo",
        render: (_, record) => record.panNo,
      },
      {
        title: "Sub Introducer",
        dataIndex: "seconderCode",
        render: (_, record) => record.seconderCode,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (_, elm) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title="View">
              <Button
                type="info"
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => showUserProfile(elm)}
                size="small"
              />
            </Tooltip>
            <Tooltip title="Update">
              <Button
                type="info"
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => editMemberRoute(elm.id)}
                size="small"
              />
            </Tooltip>
            {user.backyardUserRoleId === "1" && (
              <Popconfirm
                placement="bottomLeft"
                title={`Are you sure to delete ${elm.firstName}?`}
                onConfirm={() => confirm(elm.id)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete">
                  <Button
                    style={{ color: "red" }}
                    icon={<DeleteOutlined />}
                    onClick={() => {}}
                    size="small"
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ],
    [showUserProfile, editMemberRoute, confirm, cancel, user.backyardUserRoleId]
  );
  const skeletonColumns = useMemo(
    () =>
      tableColumns.map((col) => ({
        ...col,
        render: () => (
          <Skeleton.Button style={{ width: "120px", height: 32 }} active />
        ),
      })),
    [tableColumns]
  );

  const skeletonData = useMemo(
    () =>
      Array.from({ length: 7 }, (_, index) => ({ key: `skeleton-${index}` })),
    []
  );

  const handleExportData = async () => {
    dispatch(exportMembers());
  };

  useEffect(() => {
    if (exportData && exportData.length > 0) {
      exportToExcel(exportData);
    }
  }, [exportData]);

  const exportToExcel = (data) => {
    const transformedData = data.map((item) => ({
      membershipCode: item.membershipCode,
      fullName: formatFullName(item.firstName, item.middelName, item.lastName),
      mobileNumber: item.mobileNumber,
      introducer: item.introducerCode,
      subIntroducer: item.seconderCode,
      gender: item.gender === "1" ? "Female" : item.gender === "0" ? "Male" : "",
      memberRelation: roleMap[item.memberRelationId] || "Unknown",
      panNumber: item.panNo,
      internalCode: item.internalCardNumber,
      externalCode: item.externalCardNumber,
      dateOfBirth: item.dateOfBirth,
      registrationDate: `${item.registrationDate.split(" ")[0] || ""}`,
      email: item.email,
      photo: item.photo,
      memberStatus: item.memberStatus === "1" ? "Active" : "Inactive",
      postalAddress: item.postalAddress,
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    const headers = [
      "Membership Code",
      "Full Name",
      "Mobile Number",
      "Introducer",
      "Sub Introducer",
      "Gender",
      "Member Relation",
      "Pan Number",
      "Internal Code",
      "External Code",
      "Date Of Birth",
      "Registration Date",
      "Email",
      "Photo",
      "Member Status",
      "Postal Address",
    ];

    headers.forEach((header, index) => {
      worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = { v: header };
    });

    const maxWidths = headers.map((_, i) =>
      Math.max(
        ...transformedData.map(
          (row) =>
            (row[headers[i].toLowerCase().replace(/ /g, "")] || "").toString()
              .length
        ),
        headers[i].length
      )
    );

    worksheet["!cols"] = maxWidths.map((width) => ({ wch: width }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Members");

    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, "0")}-${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${today.getFullYear()}`;

    const fileName = `All-Members-${formattedDate}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  return (
    <Layout>
      <Card>
        <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
          <Flex className="mb-1" mobileFlex={false}>
            <div className="mr-md-3 mb-3">
              <Input
                defaultValue={searchTerm}
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={handleSearch}
                allowClear
              />
            </div>
            <h5 className="d-flex align-items-center">
              <Tag color={total < 1 ? "red" : "green"}>
                {`Total Results Found: `} {`${total}`}
              </Tag>
            </h5>
          </Flex>
          <Flex className="mb-1" mobileFlex={false}>
            <div className="addButton">
              <Button
                onClick={addMemberRoute}
                type="primary"
                icon={<PlusCircleOutlined />}
                block
              >
                Add Member
              </Button>
            </div>
            <div className="addButton">
              <Button
                link
                loading={isExportLoading}
                style={{
                  marginLeft: "10px",
                  background:
                    "linear-gradient(120deg, #b3e07b 0%, #7dbf5a 100%)",
                  color: "white",
                  fontWeight: "500",
                  // boxShadow: "0 4px 10px rgba(123, 182, 98, 0.6)",
                  transition: "background 0.3s ease",
                  border: "none",
                }}
                onClick={handleExportData}
              >
                Export Data <ExportOutlined />
              </Button>
            </div>
          </Flex>
        </Flex>
        <div className="table-responsive">
          <Table
            columns={
              isLoading || isDeletingLoading ? skeletonColumns : tableColumns
            }
            dataSource={isLoading || isDeletingLoading ? skeletonData : data}
            rowKey="id"
            pagination={{
              current: currentPage,
              pageSize,
              total: total,
              showSizeChanger: false,
            }}
            onChange={handleTableChange}
          />
        </div>
        {selectedUser && (
          <UserView
            data={selectedUser}
            visible={userProfileVisible}
            loading={true}
            close={closeUserProfile}
          />
        )}
      </Card>
    </Layout>
  );
}
