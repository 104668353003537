import { createSlice } from "@reduxjs/toolkit";
import { fetchStaff } from "../../api/fetchStaff";

const initialState = {
  data: null,
  isLoading: false,
  error: "",
};

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    clearStaff: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStaff.pending, (state) => {
        state.isLoading = true;
        state.data = null;
      })
      .addCase(fetchStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload.Data;
      })
      .addCase(fetchStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch staff";
      });
  },
});

export const { clearStaff } = staffSlice.actions;
export default staffSlice.reducer;
