import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPincodeDetails = createAsyncThunk(
  "pincode/fetchPincodeDetails",
  async ({ pincode_id }, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pincode_id}`
      );

      return response.data[0].PostOffice[0];
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to fetch pincode details"
      );
    }
  }
);
