import React, { useEffect } from "react";
import Layout from "../../Layouts/Layout";
import { Button, Card, Form, message, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import GeneralForm from "../../Components/event/GeneralForm";
import Flex from "../../Components/Ui/Flex";
import { useDispatch, useSelector } from "react-redux";
import { getBase64 } from "../../utils/file";
import { addEvent } from "../../store/api/addEvent";

const AddEvents = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAddingLoading } = useSelector((state) => state.addEventReducer);

  useEffect(() => {
    form.resetFields();
  }, []);

  const onDiscard = () => {
    navigate("/events");
    form.resetFields();
  };

  const onFinish = (values) => {
    const payload = { ...values };

    if (!payload.featuredImage) {
      delete payload.featuredImage;
    }

    if (!payload.bannerImage) {
      delete payload.bannerImage;
    }
    dispatch(addEvent(payload))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          message.success("Event successfully added!");
          navigate("/events");
        } else {
          message.error(response.Message);
        }
      })
      .catch((error) => {
        message.error("Failed to add member. Please try again.");
        console.error("Add member error:", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const handleRemove = (key) => {
    form.setFieldsValue({
      [key]: null,
    });
  };
  const beforeUpload = (file) => {
    const isValidFileType = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/jpg",
    ].includes(file.type);

    if (!isValidFileType) {
      message.error("You can only upload JPG, PNG, or WEBP files!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = async ({ fileList }, key) => {
    if (fileList.length > 0) {
      const file = fileList[fileList.length - 1];

      const base64 = await getBase64(file.originFileObj);

      form.setFieldsValue({
        [key]: base64,
      });
    }
  };

  const onFormValuesChange = (changedValues) => {
    if (
      changedValues.bannerImage &&
      typeof changedValues.bannerImage !== "string"
    ) {
      handleRemove();
    }
    if (
      changedValues.featuredImage &&
      typeof changedValues.featuredImage !== "string"
    ) {
      handleRemove();
    }
  };
  return (
    <>
      <Layout>
        <Card>
          <Form
            form={form}
            layout="vertical"
            onValuesChange={onFormValuesChange}
            name="advanced_search"
            className="ant-advanced-search-form"
            initialValues={{ status: "1" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="container">
              <Flex
                className="py-2"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">Add New Event</h2>
                <div className="mb-3">
                  <Button className="mr-2" onClick={() => onDiscard()}>
                    Discard
                  </Button>
                  <Button
                    type="primary"
                    loading={isAddingLoading}
                    htmlType="submit"
                  >
                    Add
                  </Button>
                </div>
              </Flex>
            </div>
            <div className="container mt-0">
              <GeneralForm
                form={form}
                beforeUpload={beforeUpload}
                handleChange={handleChange}
                handleRemove={handleRemove}
              />
            </div>
          </Form>
        </Card>
      </Layout>
    </>
  );
};

export default AddEvents;
