import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const fetchUpcomingCode = createAsyncThunk(
  "upcoming/fetchUpcomingCode",
  async (params, thunkAPI) => {
    try {
      const response = await instance.get("/membership/upcoming-code");

      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to fetch upcoming data"
      );
    }
  }
);
