import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../services/axiosService";

export const deleteGalleryImage = createAsyncThunk(
  "gallery/deleteGalleryImage",
  async (data, thunkAPI) => {
    try {
      const response = await instance.delete(
        `/gallery/${data.gallery_id}/image/${data.id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to delete gallery image"
      );
    }
  }
);
