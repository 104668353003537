export const calculateAge = (dob) => {
  if (!dob) return "";
  const birthDate = new Date(dob);
  const today = new Date();
  let calculatedAge = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    calculatedAge--;
  }
  return calculatedAge >= 0 ? calculatedAge : "";
};

export const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
export const eventMap = {
  1: "Upcoming",
  2: "Running",
  3: "Complete",
};

export const roleMap = {
  1: "Main",
  2: "Spouse",
  3: "Son",
  4: "Daughter",
  5: "Father",
  6: "Mother",
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Function to format the full name with each part capitalized
export const formatFullName = (firstName, middleName, lastName) => {
  return `${capitalizeFirstLetter(firstName || "")} ${capitalizeFirstLetter(
    middleName || ""
  )} ${capitalizeFirstLetter(lastName || "")}`;
};
export function formatDate(dateString) {
  const date = new Date(dateString);

  if (isNaN(date)) {
    return "Invalid Date";
  }

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  return `${day.toString().padStart(2, "0")} ${month}, ${year}`;
}

export const formatTimer = (time) => {
  return String(time).padStart(2, "0");
};