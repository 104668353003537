import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import Login from "./Pages/Login";
import Dashboard from "./Pages/dashboard/Dashboard";
import Members from "./Pages/members/Members";
import AddMembers from "./Pages/members/AddMembers";
import UploadMembers from "./Pages/upload-members/UploadMembers";
import MembersMaintenance from "./Pages/members-maintenance/MembersMaintenance";
import EditMembers from "./Pages/members/EditMembers";
import Events from "./Pages/events/Events";
import AddEvents from "./Pages/events/AddEvents";
import EditEvents from "./Pages/events/EditEvents";
import Staff from "./Pages/staff/Staff";
import AddStaff from "./Pages/staff/AddStaff";
import EditStaff from "./Pages/staff/EditStaff";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import ChangePassword from "./Pages/ChangePassword";
import VisitedMembers from "./Pages/events/VisitedMembers";
import Gallery from "./Pages/gallery/Gallery";
import EditGallery from "./Pages/gallery/EditGallery";
import AddGallery from "./Pages/gallery/AddGallery";
import AppConfig from "./Pages/AppConfig";
import PushNotification from "./Pages/PushNotification";
import QrCode from "./Pages/qr-code/QrCode";
import QrCodeDetails from "./Pages/qr-code/QrCodeDetails";
import AppBanner from "./Pages/app-banner/AppBanner";
import AddBanner from "./Pages/app-banner/AddBanner";
import EditBanner from "./Pages/app-banner/EditBanner";

function App() {
  const theme = {
    token: {
      colorInfo: "#e7c873",
      colorLink: "#e7c873",
      colorLinkHover: "#e7c873",
      colorInfoBorderHover: "#e7c873",
      colorInfoHover: "#e7c873",
      colorInfoText: "#f0da96",
      colorInfoActive: "#e7c873",
      controlItemBgActive: "#e7c873",
      colorInfoTextActive: "#e7c873",
      colorLinkActive: "#e7c873",
      colorPrimary: "#e7c873",
      colorPrimaryHover: "#d9b45e",
      colorPrimaryActive: "#c9a449",
      colorPrimaryBorder: "#e7c873",
      colorPrimaryBorderHover: "#d9b45e",
      colorPrimaryBorderActive: "#c9a449",
      colorPrimaryText: "#f0da96",
      colorPrimaryTextHover: "#d9b45e",
      colorPrimaryTextActive: "#c9a449",

      colorSecondary: "#ff7f00",
      colorSecondaryHover: "#e67300",
      colorSecondaryActive: "#cc6600",
      colorSecondaryBorder: "#ff7f00",
      colorSecondaryBorderHover: "#e67300",
      colorSecondaryText: "#ff7f00",
      colorSecondaryTextHover: "#e67300",
      colorSecondaryTextActive: "#cc6600",

      // You can add more color properties here as needed
    },
    components: {
      Button: {
        colorPrimary: "#ff7f00",
        colorPrimaryHover: "#e67400",
        colorPrimaryActive: "#cc6600",
        primaryColor: "#ff7f00",
        primaryHoverColor: "#e67400",
        primaryActiveColor: "#cc6600",
        defaultBg: "#ff7f00",
        defaultBorderColor: "#ff7f00",
        defaultColor: "#ffffff",
        defaultHoverBg: "#e67400",
        defaultHoverBorderColor: "#e67400",
        defaultHoverColor: "#ffffff",
        defaultActiveBg: "#cc6600",
        defaultActiveBorderColor: "#cc6600",
      },
      Switch: {
        colorPrimary: "#ff7f00",
        colorPrimaryHover: "#e67400",
        colorPrimaryActive: "#cc6600",
        defaultBg: "#ff7f00",
        defaultBorderColor: "#ff7f00",
        defaultColor: "#ffffff",
        defaultHoverBg: "#e67400",
        defaultHoverBorderColor: "#e67400",
        defaultHoverColor: "#ffffff",
        defaultActiveBg: "#cc6600",
        defaultActiveBorderColor: "#cc6600",
      },
      // Input: {
      //   colorPrimary: "#e7c873",
      //   colorPrimaryHover: "#d9b45e",
      //   colorPrimaryActive: "#c9a449",
      //   primaryColor: "#e7c873",
      //   primaryHoverColor: "#d9b45e",
      //   primaryActiveColor: "#c9a449",
      //   defaultBg: "#e7c873",
      //   defaultBorderColor: "#e7c873",
      //   defaultColor: "#ffffff",
      //   defaultHoverBg: "#d9b45e",
      //   defaultHoverBorderColor: "#d9b45e",
      //   defaultHoverColor: "#ffffff",
      //   defaultActiveBg: "#c9a449",
      //   defaultActiveBorderColor: "#c9a449",
      // },
      // Add more component-specific customizations here
    },
  };

  return (
    <ConfigProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
          <Route
            path={`${process.env.PUBLIC_URL}/qr-code`}
            element={<QrCode />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/qr-code/:id`}
            element={<QrCodeDetails />}
          />

          <Route element={<PrivateRoute />}>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Members />} />
            <Route
              path={`${process.env.PUBLIC_URL}/dashboard`}
              element={<Dashboard />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/members`}
              element={<Members />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/staff`}
              element={<Staff />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/events`}
              element={<Events />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/upload-members`}
              element={<UploadMembers />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/maintenance`}
              element={<MembersMaintenance />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/members/add-members`}
              element={<AddMembers />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/events/add-events`}
              element={<AddEvents />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/events/visited-member/:id`}
              element={<VisitedMembers />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/staff/add-staff`}
              element={<AddStaff />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/gallery/add-gallery`}
              element={<AddGallery />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/members/edit-members/:id`}
              element={<EditMembers />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/events/edit-events/:id`}
              element={<EditEvents />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/staff/edit-staff/:id`}
              element={<EditStaff />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/change-password`}
              element={<ChangePassword />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/gallery`}
              element={<Gallery />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/gallery/edit-gallery/:id`}
              element={<EditGallery />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app-config`}
              element={<AppConfig />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app-banner`}
              element={<AppBanner />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app-banner/add-banner`}
              element={<AddBanner />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app-banner/edit-banner/:id`}
              element={<EditBanner />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/push-notification`}
              element={<PushNotification />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
