import { combineReducers, configureStore } from "@reduxjs/toolkit";
import signUpReducer from "./feature/auth/signupSlice";
import membersReducer from "./feature/members/membersSlice";
import familyMembersReducer from "./feature/family-members/familyMembersSlice";
import pincodeReducer from "./feature/postal-code";
import memberReducer from "./feature/member/memberSlice";
import addMemberReducer from "./feature/add-member/addMemberSlice";
import updateMemberReducer from "./feature/update-member/updateMemberSlice";
import staffsReducer from "./feature/staffs/staffsSlice";
import addStaffReducer from "./feature/add-staff/addStaffSlice";
import staffReducer from "./feature/staff/staffSlice";
import updateStaffReducer from "./feature/update-staff/updateStaffSlice";
import deleteStaffReducer from "./feature/delete-staff/deleteStaffSlice";
import upcomingCodeReducer from "./feature/upcoming-code/upcomingCodeSlice";
import updateActiveDeactiveMemberReducer from "./feature/update-active-deactive-member/updateActiveDeactiveMemberSlice";
import deleteMemberReducer from "./feature/delete-member/deleteMemberSlice";
import changePasswordReducer from "./feature/change-password/changePasswordSlice";
import eventsReducer from "./feature/events/eventsSlice";
import addEventReducer from "./feature/add-event/addEventSlice";
import deleteEventReducer from "./feature/delete-event/deleteEventSlice";
import updateEventReducer from "./feature/update-event/updateEventSlice";
import eventReducer from "./feature/event/eventSlice";
import galleryReducer from "./feature/gallery/gallerySlice";
import singleGalleryReducer from "./feature/singleGallery/singleGallerySlice";
import visitedMembersReducer from "./feature/visited-members/visitedMembersSlice";
import addGalleryImageReducer from "./feature/add-gallery-image/addGallerySlice";
import deleteGalleryImageReducer from "./feature/delete-gallery-image/deleteGallerySlice";
import deleteGalleryReducer from "./feature/delete-gallery/deleteGallSlice";
import addGalleryReducer from "./feature/add-gallery/addGallerySlice";
import updateGalleryReducer from "./feature/update-gallery/updateGallerySlice";
import appConfigReducer from "./feature/app-config/appConfigSlice";
import addAppSettingReducer from "./feature/add-app-setting/addAppSetting";
import pushNotificationReducer from "./feature/push-notification/pushNotificationSlice";
import exportMembersReducer from "./feature/export-member/exportMembersSlice";
import exportVisitedMemberReducer from "./feature/visited-members/exportVisitedMembersSlice";
import sendOTPReducer from "./feature/send-otp/sendOTPSlice";
import verifyOTPReducer from "./feature/verify-otp/verifyOTPSlice";
import membershipTokenReducer from "./feature/fetch-membership-token/membershipTokenSlice";
import bannersReducer from "./feature/banners/bannersSlice";
import deleteBannerReducer from "./feature/delete-banner/deleteBannerSlice";
import addBannerReducer from "./feature/add-banner/addBannerSlice";
import singleBannerReducer from "./feature/singleBanner/singleBannerSlice";
import updateBannerReducer from "./feature/update-banner/updateBannerSlice";

const rootReducer = combineReducers({
  signUpReducer,
  membersReducer,
  familyMembersReducer,
  pincodeReducer,
  memberReducer,
  addMemberReducer,
  updateMemberReducer,
  staffsReducer,
  addStaffReducer,
  staffReducer,
  updateStaffReducer,
  deleteStaffReducer,
  upcomingCodeReducer,
  updateActiveDeactiveMemberReducer,
  deleteMemberReducer,
  changePasswordReducer,
  eventsReducer,
  addEventReducer,
  deleteEventReducer,
  updateEventReducer,
  eventReducer,
  galleryReducer,
  singleGalleryReducer,
  visitedMembersReducer,
  addGalleryImageReducer,
  deleteGalleryImageReducer,
  deleteGalleryReducer,
  addGalleryReducer,
  updateGalleryReducer,
  appConfigReducer,
  addAppSettingReducer,
  pushNotificationReducer,
  exportMembersReducer,
  exportVisitedMemberReducer,
  sendOTPReducer,
  verifyOTPReducer,
  membershipTokenReducer,
  bannersReducer,
  deleteBannerReducer,
  addBannerReducer,
  singleBannerReducer,
  updateBannerReducer
});
export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};
