import { createSlice } from "@reduxjs/toolkit";
import { fetchMembers } from "../../api/fetchMembers";
import { fetchGallery } from "../../api/fetchGallery";

const initialState = {
  data: [],
  page: 1,
  limit: 5,
  total: 0,
  isLoading: false,
  error: "",
};
export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.data = action.payload.data;
        state.page = action.payload.page;
        state.total = action.payload.total;
      })
      .addCase(fetchGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch data";
      });
  },
});

export default gallerySlice.reducer;
